import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ContentVO } from 'src/app/models/content-vo';
import { ApiService } from 'src/app/utility/global-services/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { GlobalConstants } from 'src/app/utility/global-constants/global-constants';
import { SubjectService } from 'src/app/utility/global-services/subject.service';
import { HelperFunctionsService } from 'src/app/utility/global-services/helper-functions.service';
import { ConfigService } from 'src/app/utility/global-services/config.service';

@Component({
  selector: 'app-onnet-content-grid',
  templateUrl: './content-grid.component.html',
  styleUrls: ['./content-grid.component.sass']
})
export class ContentGridComponent implements OnInit, OnDestroy {

  constructor(
    private apiService: ApiService,
    private snackBar: MatSnackBar,
    private router: Router,
    private helperService: HelperFunctionsService,
    private subjectService: SubjectService,
    private config: ConfigService
  ) { }

  contentsPerPage = 5;
  pathParams: string;
  contentType: string;

  @Input() contents: ContentVO[] = [];
  @Input() id: string
  @Input() parent = {} as ContentVO;
  @Input() startNewVideo: boolean;

  ngOnInit() {
    this.updateContentsPerPage();
    window.addEventListener('resize', this.updateContentsPerPage.bind(this));
  }

  ngOnDestroy() {
    window.removeEventListener('resize', this.updateContentsPerPage.bind(this));
  }

  updateContentsPerPage() {
    const w = window.innerWidth;
    if (w > 1200) {
      this.contentsPerPage = 5;
    } else if (w <= 1200 && w > 900) {
      this.contentsPerPage = 4;
    } else if (w <= 900) {
      this.contentsPerPage = 3;
    }
  }

  getContentThumbnail(content: ContentVO) {
    let url = './assets/images/thumbnail-default.jpg';
    if (content.thumbnailInformation && content.thumbnailInformation.length > 0) {
      if (content.thumbnailInformation[0].orient === 0) {
        url = content.thumbnailInformation[0].url;
      } else if (content.thumbnailInformation[1] && content.thumbnailInformation[1].orient === 0) {
        url = content.thumbnailInformation[1].url;
      }
    }
    return url;
  }

  childrenInfoClicked(event: Event, item: any, clickType: string) {
    event.stopPropagation();
    this.selectContent(event, item, clickType);
  }

  selectContent(event: Event, content: ContentVO, clickType: string) {
    console.log(event, content, clickType)
    console.log(this.parent)

    event.stopPropagation();
    const url = this.resolvePath(content);
    // const body = {
    //   id:content.id,
    //   categoryId: content.categoryId
    // };
    const body = {
      id: content.id || content.subCategoryId,
      categoryId: content.categoryId || this.id
      ,
    };
    console.log(body)

    this.apiService.post(url, body).subscribe(
      (res: any) => {
        const contentInfo = res.data;
        window.localStorage.setItem(this.contentType, JSON.stringify(contentInfo));
        window.localStorage.setItem(this.contentType + 'VO', JSON.stringify(content));
        window.localStorage.setItem('clickType', clickType);
        // this.helperService.setBreadCrumbs(this.parent);
        this.helperService.setNavigationData(content.contentType, content.contentType, content);
        const navigationPath = ['content', this.pathParams, content.subCategoryId || content.id];
        if (this.pathParams && (content.subCategoryId || content.id)) {
          this.router.navigate(navigationPath);
          this.callAppropriateSubject(content);
        } else {
          this.snackBar.open('Navigation path is not valid', 'close', { duration: 3000 });
        }
      }, (err) => {
        this.snackBar.open('Failed to load data', 'close', { duration: 3000 });
      });
  }

  resolvePath(content: ContentVO): string {
    if (content.contentType === 0) {
      this.pathParams = 'asset';
      this.contentType = GlobalConstants.asset;
      return this.config.json.apiIpPortValue + 'asset/getAssetInfoById.on';
    } else if (content.contentType === 1) {
      this.pathParams = 'sub';
      this.contentType = GlobalConstants.subCategory;
      return this.config.json.apiIpPortValue + 'category/getCategoryInfoById.on';
    } else if (content.contentType === 2) {
      this.pathParams = 'series';
      this.contentType = GlobalConstants.series;
      return this.config.json.apiIpPortValue + 'series/getSeriesInfoById.on';
    } else if (content.contentType === 3) {
      this.pathParams = 'live';
      this.contentType = GlobalConstants.live;
      return this.config.json.apiIpPortValue + 'channel/getChannelInfoById.on';
    }
    return '';
  }

  callAppropriateSubject(content: ContentVO) {
    if (content.contentType === 0) {
      this.subjectService.setAssetOnSameNavigation(true);
    } else if (content.contentType === 1) {
      this.subjectService.setCategoryOnSameNavigation(true);
    } else if (content.contentType === 3) {
      this.subjectService.setLiveOnSameNavigation(true);
    }
  }

  playVideo(event: Event, content: ContentVO, clickType: string) {
    event.stopPropagation();
    this.selectContent(event, content, clickType);
  }

  addHoverClass(e: MouseEvent) {
    const element = (e.currentTarget as HTMLElement).parentElement?.parentElement;
    element?.classList.add('hover');
    const parts = (e.currentTarget as HTMLElement).classList[1].split('-');
    const thumbnailPosition = Number(parts[parts.length - 1]);
    if (thumbnailPosition % this.contentsPerPage === 0) {
      element?.classList.add('hover-left');
    } else if ((thumbnailPosition - 1) % this.contentsPerPage === 0) {
      element?.classList.add('hover-right');
    } else {
      element?.classList.add('hover-center');
    }
  }

  removeHoverClass(e: MouseEvent) {
    const element = (e.currentTarget as HTMLElement).parentElement?.parentElement;
    element?.classList.remove('hover', 'hover-left', 'hover-right', 'hover-center');
  }

  addToFavourites(event: Event, content: ContentVO) {
    event.stopPropagation();
    const url = this.config.json.apiIpPortValue + 'favourites/setAsFavourites.on';
    const body = this.addToFavouritesResponseBody(content);
    this.apiService.post(url, body).subscribe(
      (res: any) => {
        content.favourite = true;
        this.snackBar.open('Added to favorites!', 'close', { duration: 4000 });
      },
      (err: any) => {
        this.snackBar.open('Unable to add to favorite', 'close', { duration: 4000 });
      }
    );
  }

  removeFromFavourites(event: Event, content: ContentVO) {
    event.stopPropagation();
    const url = this.config.json.apiIpPortValue + 'favourites/deleteFavourites.on';
    const body = this.removeFromFavouritesRequestBody(content);
    this.apiService.post(url, body).subscribe(
      (res: any) => {
        content.favourite = false;
        this.snackBar.open('Removed from favorites!', 'close', { duration: 4000 });
      },
      (err: any) => {
        this.snackBar.open('Unable to remove', 'close', { duration: 4000 });
      }
    );
  }

  addToFavouritesResponseBody(content: ContentVO) {
    return { contentInformation: [content] };
  }

  removeFromFavouritesRequestBody(content: ContentVO) {
    return { subCategoryId: content.subCategoryId };
  }
}
