import { Component, OnInit, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/utility/global-services/storage.service';
import { SubjectService } from 'src/app/utility/global-services/subject.service';
import { Subscription } from 'rxjs';

/**
 * Component for taking search params and emitting it to the parent component
 *
 * Actions include, click search icon, press ENTER key, and click close icon to clear search params
 */
@Component({
  selector: 'app-onnet-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.sass']
})
export class SearchBarComponent implements OnInit, OnDestroy {

  constructor(
    private router: Router,
    private storage: StorageService,
    private subjectServcie: SubjectService,
    private storageService: StorageService
  ) { }

  /**
   * Eventemitter for emitting values to parent component
   */
  @Output() searchValue: EventEmitter<any> = new EventEmitter();

  /**
   * Stores the value entered in input field
   */
  value = '';

  clearSearchValueSubscription: Subscription;

  ngOnInit() {

    this.clearSearchValueSubscription = this.subjectServcie.getClearSearchValueSubject().subscribe(val => this.value = val);
  }

  /**
   * Called when user click on search icon, clear icon or presses ENTER key, to emit the search value to parent component
   */
  emitSearchValue() {
    this.searchValue.emit(this.value);
    // this.emitAndNavigate();
  }

  /**
   * Called when there is a keydown event, to check is ENTER key is pressed.
   *
   * @param event : keydown event
   */
  onKeyDown(event) {
    if (event.key === 'Enter' && this.value.trim() !== '') {
      // this.emitSearchValue();
      this.emitAndNavigate();
    }
  }

  emitAndNavigate() {
    window.localStorage.setItem('searchValue', this.value.trim());
    this.router.navigate(['content', 'search']);
    this.subjectServcie.setSearchValueSubject(this.value.trim());
  }

  clickCancel() {
    this.value = '';
    this.emitSearchValue();
  }

  ngOnDestroy() {
    this.clearSearchValueSubscription.unsubscribe();
  }

}
