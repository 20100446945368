import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

/**
 * Interceptor for handling all API requests being made
 *
 * Any global modifications to be done to all api calls, can be implemented here
 * TODO: Need to check if providedIn: Root option can be added here, after apis' integration
 */
@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // if (req.url && !req.url.toString().includes('auth/login')) {
    if (req.url.includes('subscriber')) {
      let token;
      if (window.localStorage.getItem('subscriberAuthToken')) {
        token = window.localStorage.getItem('subscriberAuthToken');
      }

      if (token) {
        req = req.clone({
          setHeaders: {
            Authorization: 'Bearer ' + token
          }
        });
      }

      if (!req.headers.has('content-Type')) {
        req = req.clone({
          setHeaders: {
            'content-type': 'application/json'
          }
        });
      }
    } else if (req.url.includes('statistics')) {
      if (!req.headers.has('content-Type')) {
        req = req.clone({
          setHeaders: {
            'content-type': 'application/json'
          }
        });
      }
    }
    // }
    // //console.log(req);
    return next.handle(req);
  }

}
