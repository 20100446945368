import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter, OnChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-onnet-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.sass']
})
export class DatePickerComponent implements OnInit, OnChanges {

  constructor() { }

  // Holds the date format. Initialised to default date format
  @Input() private format = 'DD/MM/YYYY';

  // Reference to date picker input field
  @ViewChild('dateInput', { static: false }) dateInput: ElementRef;

  @ViewChild('dp', { static: false }) datePicker: ElementRef;

  // Emits an event whenever there is a change in date selected
  @Output() dateSelected = new EventEmitter<any>();

  // Date passed from parent.
  @Input() date: string;

  @Input() restrictDate = false;

  /**
   * Holds the selected date
   */
  selectedDate: string;

  // Contains the date till which we need to restrict selection in calendar
  restrictFutureDate;

  // Date input form, used to access the date in date picker
  dateForm = new FormControl();

  ngOnInit() {
    // Checking if any date has been sent from parent component
    if (this.date) {
      this.dateForm.setValue(moment(this.date, this.format));
    }

    if (this.restrictDate) {
      this.setRestrictDate();
    }
  }

  ngOnChanges() {
    // Checking if any date has been sent from parent component
    if (this.date) {
      //console.log(this.date);
      this.dateForm.setValue(moment(this.date, this.format));
    }
  }

  setRestrictDate() {
    this.restrictFutureDate = new Date();
  }

  /**
   * Event handler function that gets called whenever there is a change in the date selected
   * @param date : matdatepickerevent
   */
  dateChange(date) {
    this.selectedDate = this.dateInput.nativeElement.value;
    this.dateSelected.emit(this.selectedDate);
  }

}
