import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoreRoutingModule } from './core-routing.module';
import { CoreComponent } from './core/core.component';
import { NavigationModule } from '../navigation/navigation.module';
import { HttpClientModule } from '@angular/common/http';


@NgModule({
  declarations: [CoreComponent],
  imports: [
    CommonModule,
    CoreRoutingModule,
    NavigationModule,
    HttpClientModule
  ],
  exports: [
    CoreComponent
  ]
})
export class CoreModule { }
