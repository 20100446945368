import { Component, OnInit, Input } from '@angular/core';
import { ContentVO } from 'src/app/models/content-vo';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from 'src/app/utility/global-services/api.service';
import { GlobalConstants } from 'src/app/utility/global-constants/global-constants';
import { Router } from '@angular/router';
import { ConfigService } from 'src/app/utility/global-services/config.service';

@Component({
  selector: 'app-onnet-favourite-grid',
  templateUrl: './favourite-grid.component.html',
  styleUrls: ['./favourite-grid.component.sass']
})
export class FavouriteGridComponent implements OnInit {

  constructor(
    private snackBar: MatSnackBar,
    private apiService: ApiService,
    private route: Router,
    private config : ConfigService
  ) { }

  subscriberId;

  contentsPerPage = 5;

  pathParams: string;

  contentType: string;

  @Input() contents: ContentVO[] = [];

  ngOnInit() {
    const parent = this;
    function responsiveChanges() {
      const w = window.innerWidth;
      if (w > 1200) {
        parent.contentsPerPage = 5;
      } else if (w <= 1200 && w > 900) {
        parent.contentsPerPage = 4;
      } else if (w <= 900) {
        parent.contentsPerPage = 3;
      }
    }

    window.addEventListener('resize', responsiveChanges);
    responsiveChanges();

    this.subscriberId = window.localStorage.getItem('subscriberId');

  }

  getThumbnail(content: ContentVO) {
    const url = content.thumbnailInformation[0].orient === 0 ? content.thumbnailInformation[0].url :
      (content.thumbnailInformation[1] && content.thumbnailInformation[1].orient === 0 ? content.thumbnailInformation[1].url
        : undefined);
    return url;
  }

  getContentThumbnail(content: ContentVO) {
    // "./assets/images/sample1.jpg"
    let url = './assets/images/thumbnail-default.jpg';
    if (content.thumbnailInformation && content.thumbnailInformation.length > 0) {
      if (content.thumbnailInformation[0].orient === 0) {
        url = content.thumbnailInformation[0].url;
      } else if (content.thumbnailInformation[1].orient
        && content.thumbnailInformation[1].orient === 0) {
        url = content.thumbnailInformation[1].url;
      }
    }
    return url;
  }

  removeFromFavorites(event, content) {
    event.stopPropagation();
    const url = this.config.json.apiIpPortValue + 'favourites/deleteFavourites.on';
    const body = this.createRequestBody(content);
    this.apiService.post(url, body).subscribe(
      (res) => {
        //console.log(res);
        if (res.status === 'SUCCESS') {
          this.removeFromList(content);
        }
      },
      (err) => {
        this.snackBar.open('No data', 'close', { duration: 4000 });
      }
    );
  }

  createRequestBody(content) {
    const obj = {
      subscriberId: this.subscriberId,
      subCategoryId: content.subCategoryId
    };
    return obj;
  }

  removeFromList(content) {
    let index;
    this.contents.forEach(ele => {
      if (ele.subCategoryId === content.subCategoryId) {
        index = this.contents.indexOf(ele);
      }
    });
    this.contents.splice(index, 1);
  }

  playContent(event, content: ContentVO) {
    event.stopPropagation();
    window.localStorage.setItem('clickType', GlobalConstants.playContent);
    this.selectContent(content);
  }

  loadContent(content: ContentVO) {
    window.localStorage.setItem('clickType', GlobalConstants.pageContent);
    this.selectContent(content);
  }

  selectContent(content: ContentVO) {
    const url = this.resolvePath(content);
    this.apiService.post(url, { id: content.subCategoryId }).subscribe(
      (res) => {
        const contentInfo = res.data;
        window.localStorage.setItem(this.contentType, JSON.stringify(contentInfo));
        window.localStorage.setItem(this.contentType + 'VO', JSON.stringify(content));
        // window.localStorage.setItem('clickType', clickType);
        this.route.navigate(['content', this.pathParams, content.subCategoryId]);
      }, (err) => {
        this.snackBar.open('Failed to load data', 'close', { duration: 3000 });
        // window.localStorage.removeItem('clickType');
      });
  }

  resolvePath(content: ContentVO) {
    if (content.contentType === 0) {
      this.pathParams = 'asset';
      this.contentType = GlobalConstants.asset;
      return this.config.json.apiIpPortValue + 'asset/getAssetInfoById.on';
    } else if (content.contentType === 1) {
      this.pathParams = 'sub';
      this.contentType = GlobalConstants.subCategory;
      return this.config.json.apiIpPortValue + 'category/getCategoryInfoById.on';
    } else if (content.contentType === 2) {
      this.pathParams = 'series';
      this.contentType = GlobalConstants.series;
      return this.config.json.apiIpPortValue + 'series/getSeriesInfoById.on';
    } else if (content.contentType === 3) {
      this.pathParams = 'live';
      this.contentType = GlobalConstants.live;
      return this.config.json.apiIpPortValue + 'channel/getChannelInfoById.on';
    }
  }


  // Mouse hover effects

  addHoverClass(e) {
    e.currentTarget.parentElement.parentElement.classList.add('hover');
    const parts = e.currentTarget.classList[1].split('-');
    const thumbnailPosition = Number(parts[parts.length - 1]);


    if ((thumbnailPosition % this.contentsPerPage === 0)) {
      e.currentTarget.parentElement.parentElement.classList.add('hover-left');
    } else if (((thumbnailPosition - 1) % this.contentsPerPage === 0)) {
      e.currentTarget.parentElement.parentElement.classList.add('hover-right');
    } else {
      e.currentTarget.parentElement.parentElement.classList.add('hover-center');
    }
  }


  removeHoverClass(e) {
    e.currentTarget.parentElement.parentElement.classList.remove('hover');
    e.currentTarget.parentElement.parentElement.classList.remove('hover-left');
    e.currentTarget.parentElement.parentElement.classList.remove('hover-right');
    e.currentTarget.parentElement.parentElement.classList.remove('hover-center');
  }

}
