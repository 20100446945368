import { Injectable } from '@angular/core';
import { AssetInfo } from 'src/app/models/asset-info';
import { ContentVO } from 'src/app/models/content-vo';
import { ApiService } from './api.service';
import { SubjectService } from './subject.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { GlobalConstants } from '../global-constants/global-constants';
import { Category } from 'src/app/models/category';
import { Series } from 'src/app/models/series';
import { LiveChannel } from 'src/app/models/live-channel';
import { Season } from 'src/app/models/season';
import { SeasonVO } from 'src/app/models/season-vo';
import { NavigationData } from 'src/app/models/navigation-data';
import { CategoryInformation } from 'src/app/models/category-information';
import { ConfigService } from './config.service';


@Injectable({
  providedIn: 'root'
})
export class HelperFunctionsService {

  constructor(
    private apiService: ApiService,
    private snackBar: MatSnackBar,
    private route: Router,
    private subjectService: SubjectService,
    private config: ConfigService
  ) { }

  contentType;
  pathParams;

  pathValue = GlobalConstants.pathValue;

  createContentVOFromAssetList(data: AssetInfo[]) {
    const relatedList = [];
    data.forEach(ele => {
      const item = {} as ContentVO;
      item.subCategoryId = ele.id;
      item.contentType = 0;
      item.name = ele.assetTitle;
      item.subCategoryDisplayTags = ele.assetDisplayTags ? ele.assetDisplayTags : [];
      item.thumbnailInformation = ele.thumbnailInformation ? ele.thumbnailInformation : [];
      item.favourite = ele.favourite ? ele.favourite : false;
      relatedList.push(item);
    });
    return relatedList;
  }

  createContentVOFromSubCategoryList(data: Category[]) {
    const relatedList = [];
    data.forEach(ele => {

      if ((ele.assetInformation && ele.assetInformation.length > 0) || (ele.subCategoryInformation && ele.subCategoryInformation.length > 0)
        || (ele.seriesInformation && ele.seriesInformation.length > 0) || (ele.channelInformation && ele.channelInformation.length > 0)) {

        ele = this.modifyCategoryModel(ele);
      }

      const item = {} as ContentVO;
      item.subCategoryId = ele.id;
      item.contentType = 1;
      item.name = ele.categoryName;
      item.subCategoryDisplayTags = ele.categoryDisplayTags ? ele.categoryDisplayTags : [];
      item.thumbnailInformation = ele.thumbnailInformation ? ele.thumbnailInformation : [];
      item.favourite = false;
      item.childrenInfo = ele.contentInformation;
      relatedList.push(item);
    });
    return relatedList;
  }

  createContentVOFromTopCategoryList(data: Category[]) {
    const relatedList = [];
    data.forEach(ele => {

      if ((ele.assetInformation && ele.assetInformation.length > 0) || (ele.subCategoryInformation && ele.subCategoryInformation.length > 0)
        || (ele.seriesInformation && ele.seriesInformation.length > 0) || (ele.channelInformation && ele.channelInformation.length > 0)) {

        ele = this.modifyCategoryModel(ele);
      }

      const item = {} as ContentVO;
      item.subCategoryId = ele.id;
      item.contentType = 4;
      item.name = ele.categoryName;
      item.subCategoryDisplayTags = ele.categoryDisplayTags ? ele.categoryDisplayTags : [];
      item.thumbnailInformation = ele.thumbnailInformation ? ele.thumbnailInformation : [];
      item.favourite = false;
      item.childrenInfo = ele.contentInformation;
      relatedList.push(item);
    });
    return relatedList;
  }

  createContentVOFromSeriesList(data: Series[]) {
    const relatedList = [];
    data.forEach(ele => {
      const item = {} as ContentVO;
      item.subCategoryId = ele.id;
      item.contentType = 2;
      item.name = ele.seriesName;
      item.subCategoryDisplayTags = ele.seriesDisplayTags ? ele.seriesDisplayTags : [];
      item.thumbnailInformation = ele.thumbnailInformation ? ele.thumbnailInformation : [];
      item.favourite = false;
      relatedList.push(item);
    });
    return relatedList;
  }

  createContentVOFromChannelList(data: LiveChannel[]) {
    const relatedList = [];
    data.forEach(ele => {
      const item = {} as ContentVO;
      item.subCategoryId = ele.id;
      item.contentType = 3;
      item.name = ele.channelName;
      item.subCategoryDisplayTags = ele.channelDisplayTags ? ele.channelDisplayTags : [];
      item.thumbnailInformation = ele.thumbnailInformation ? ele.thumbnailInformation : [];
      item.favourite = false;
      relatedList.push(item);
    });
    return relatedList;
  }

  createSeasonVOFromSeasonList(data: Season[]) {
    const relatedList = [];
    data.forEach(ele => {
      const item = {} as SeasonVO;
      item.seasonId = ele.id;
      item.contentType = 4;
      item.name = ele.seasonName;
      item.thumbnailInformation = ele.thumbnailInformation ? ele.thumbnailInformation : [];
      item.childrenInfo = this.createContentVOFromAssetList(ele.assetInformation ? ele.assetInformation : []);
      relatedList.push(item);
    });
    return relatedList;
  }

  modifyCategoryModel(category: Category) {
    const contentList: ContentVO[] = [];

    // Checking for top level or sub level category
    if (category.categoryType === 1) {
      contentList.push(...this.createContentVOFromAssetList(category.assetInformation ? category.assetInformation : []));
      contentList.push(...this.createContentVOFromSubCategoryList(category.subCategoryInformation ? category.subCategoryInformation : []));
      contentList.push(...this.createContentVOFromSeriesList(category.seriesInformation ? category.seriesInformation : []));
      contentList.push(...this.createContentVOFromChannelList(category.channelInformation ? category.channelInformation : []));
    } else {
      contentList.push(...this.createContentVOFromSubCategoryList(category.subCategoryInformation ? category.subCategoryInformation : []));
      const otherList: ContentVO[] = [];
      otherList.push(...this.createContentVOFromAssetList(category.assetInformation ? category.assetInformation : []));
      otherList.push(...this.createContentVOFromSeriesList(category.seriesInformation ? category.seriesInformation : []));
      otherList.push(...this.createContentVOFromChannelList(category.channelInformation ? category.channelInformation : []));
      category.otherInformation = otherList;
    }
    category.contentInformation = contentList;
    return category;
  }

  modifySeriesModel(series: Series) {
    const contentList: ContentVO[] = [];
    contentList.push(...this.createContentVOFromAssetList(series.assetInformation ? series.assetInformation : []));
    series.contentInformation = contentList;

    let seasonList: SeasonVO[] = [];
    seasonList = this.createSeasonVOFromSeasonList(series.seasonsInformation ? series.seasonsInformation : []);
    series.seasonInformation = seasonList;
    return series;
  }

  modifyAssetModel(asset: AssetInfo) {
    return asset;
  }

  modifyChannelModel(channel: LiveChannel) {
    return channel;
  }

  setBreadCrumbs(content: ContentVO) {
    let list: ContentVO[] = [];
    if (window.localStorage.getItem('breadcrumbs')) {
      list = JSON.parse(window.localStorage.getItem('breadcrumbs'));
    }

    if (list.length > 0) {
      let index = -1;
      list.forEach(item => {
        if (item.subCategoryId === content.subCategoryId) {
          index = list.indexOf(item);
        }
      });

      if (index > -1) {
        // While runs till the length of the list reaches the item index
        while (list.length > index) {
          // Removing the last element of the list
          list.pop();
        }
      } else {
        // Push if the existing list doesn't contain the element
        list.push(content);
      }
    } else {
      // Else condition is satisfied when the list is empty, so we push the current element into the list
      list.push(content);
    }

    window.localStorage.setItem('breadcrumbs', JSON.stringify(list));
  }

  getBreadCrumbs() {
    let list: ContentVO[] = [];
    if (window.localStorage.getItem('breadcrumbs')) {
      list = JSON.parse(window.localStorage.getItem('breadcrumbs'));
    }

    return list;
  }

  removeBreadCrumbs() {
    window.localStorage.removeItem('breadcrumbs');
  }

  navigateBreadCrumbs(content: ContentVO) {
    this.selectContent(content);
  }

  // selectContent(content: ContentVO) {
  //   const url = this.resolvePath(content);
  //   this.apiService.post(url, { id: content.subCategoryId }).subscribe(
  //     (res) => {
  //       const contentInfo = res.data;
  //       window.localStorage.setItem(this.contentType, JSON.stringify(contentInfo));
  //       window.localStorage.setItem(this.contentType + 'VO', JSON.stringify(content));
  //       // window.localStorage.setItem('clickType', clickType);
  //       this.setBreadCrumbs(content);
  //       this.callAppropriateSubject(content);
  //       this.route.navigate(['content', this.pathParams, content.subCategoryId]);
  //     }, (err) => {
  //       this.snackBar.open('Failed to load data', 'close', { duration: 3000 });
  //       window.localStorage.removeItem('clickType');
  //     });
  // }

  categoryId: string
  selectContent(content: ContentVO) {
    console.log(content)

    const url = this.resolvePath(content); // Ensure this function is correct
    this.apiService.post(url, { id: content.subCategoryId, categoryId: content.categoryId }).subscribe(
      (res) => {
        if (res && res.data) {
          console.log(res.data)
          const contentInfo = res.data;
          window.localStorage.setItem(this.contentType, JSON.stringify(contentInfo));
          window.localStorage.setItem(this.contentType + 'VO', JSON.stringify(content));
          // window.localStorage.setItem('clickType', clickType); // Uncomment and use if needed
          this.setBreadCrumbs(content);
          this.callAppropriateSubject(content);
          this.categoryId = content.subCategoryId
          // this.route.navigate(['content', this.pathParams, content.subCategoryId]);
        } else {
          this.snackBar.open('Failed to load data', 'close', { duration: 3000 });
        }
      },
      (err) => {
        console.error('Error:', err); // Log the error for debugging purposes
        this.snackBar.open('Failed to load data', 'close', { duration: 3000 });
        window.localStorage.removeItem('clickType');
      }
    );
  }

  resolvePath(content: ContentVO) {
    console.log(content)
    if (content == undefined) {
      this.route.navigate(['content', 'home']);

    }
    else if (content.contentType === 0) {
      this.pathParams = 'asset';
      this.contentType = GlobalConstants.asset;
      return this.config.json.apiIpPortValue + 'asset/getAssetInfoById.on';
    } else if (content.contentType === 1) {
      this.pathParams = 'sub';
      this.contentType = GlobalConstants.subCategory;
      return this.config.json.apiIpPortValue + 'category/getCategoryInfoById.on';
    } else if (content.contentType === 2) {
      this.pathParams = 'series';
      this.contentType = GlobalConstants.series;
      return this.config.json.apiIpPortValue + 'series/getSeriesInfoById.on';
    } else if (content.contentType === 3) {
      this.pathParams = 'live';
      this.contentType = GlobalConstants.live;
      return this.config.json.apiIpPortValue + 'channel/getChannelInfoById.on';
    }
  }

  callAppropriateSubject(content: ContentVO) {
    if (content.contentType === 0) {
      this.subjectService.setAssetOnSameNavigation(true);
    } else if (content.contentType === 1) {
      this.subjectService.setCategoryOnSameNavigation(true);
    } else if (content.contentType === 2) {
      this.subjectService.setSeriesOnSameNavigation(true);
    } else if (content.contentType === 3) {
      this.subjectService.setLiveOnSameNavigation(true);
    }
  }

  // To clear the localstorage of valid subscriber data and subscriber token
  clearData() {
    window.localStorage.removeItem('subscriberAuthToken');
    window.localStorage.removeItem('subscriberInfo');
    window.localStorage.removeItem('subscriberIsLoggedIn');
  }




  /**
   * Adds navigation data to the stack and saves it to localstorage
   * @param pathType Number indicating the type of path
   * @param contentType Number indicating the type of content to be loaded for the particular page
   * @param contentInfo Object containing data needed to initialize the particular page
   */
  setNavigationData(pathType, contentType, contentInfo): void {
    const navData = {} as NavigationData;
    navData.path = this.pathValue[pathType];
    navData.data = contentInfo;
    navData.type = contentType;

    let navDataList: NavigationData[] = [];
    if (window.localStorage.getItem('navDataList')) {
      navDataList = JSON.parse(window.localStorage.getItem('navDataList'));
    }

    if (navDataList.length && navDataList.length < 1) {
      navDataList.push(navData);
    } else if (navDataList.length && navDataList[navDataList.length - 1].data.subCategoryId
      && (navDataList[navDataList.length - 1].data.subCategoryId === navData.data.subCategoryId)) {
      // Condition is there to prevent inserting duplicate records of same content (in case of page reloads etc.)
      navDataList.pop();
      navDataList.push(navData);
    } else {
      navDataList.push(navData);
    }

    // Writing back the nav list to be fetched when modifying the list as needed
    window.localStorage.setItem('navDataList', JSON.stringify(navDataList));
  }

  /**
   * Returns the navigation data corresponding to the previous page visited
   * @returns Object containing navigation data
   */
  getNavigationData(): NavigationData {
    let navData = {} as NavigationData;

    if (window.localStorage.getItem('navDataList')) {
      const navDataList: NavigationData[] = JSON.parse(window.localStorage.getItem('navDataList'));
      if (navDataList.length >= 1) {
        // Popping twice to remove the current page's record before reaching the previous page's record
        navData = navDataList.pop();
        navData = navDataList.pop();
      }
      // Writing back the nav list after removing the current and previous page's records
      window.localStorage.setItem('navDataList', JSON.stringify(navDataList));
    }

    return navData;
  }

  /**
   * Clears the navigation stack from localstorage
   */
  clearNavigationData(): void {
    window.localStorage.removeItem('navDataList');
  }

  /**
   * Navigates to the previous page based on the navigation data from the stack
   */
  navigateBack() {
    const navData = this.getNavigationData();
    console.log(navData, this.categoryId)
    if (!navData || navData.path == '') {
      this.route.navigate(['content', 'home']);
      return;
    }

    switch (navData.path) {
      case this.pathValue[0]: {
        this.route.navigate(['content', 'home']);
        break;
      }
      case this.pathValue[1]: {
        this.route.navigate(['content', 'sub', navData.data.subCategoryId]);
        break;
      }
      case this.pathValue[4]: {
        this.route.navigate(['content', 'home']);
        // enable this if navigation data needs to be cleared when going to home page
        // this.clearNavigationData();
        setTimeout(() => {
          const obj = {} as CategoryInformation;
          obj.id = navData.data.subCategoryId;
          this.subjectService.setTopNavSubject(obj);
        }, 250);
        break;
      }

      case this.pathValue[5]: {
        this.route.navigate(['content', 'favorites']);
        break;
      }

      case this.pathValue[6]: {
        this.route.navigate(['content', 'search']);
        break;
      }
      case undefined: {
        this.route.navigate(['content', 'home']);
        // enable this if navigation data needs to be cleared when going to home page
        this.clearNavigationData();
      }

      default: {
        this.selectContent(navData.data);
        break;
      }
    }
  }

  navigateForward(pathType, contentType, contentInfo) {
    this.setNavigationData(pathType, contentType, contentInfo);
  }

}
