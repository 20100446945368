import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { SharedModule } from '../shared/shared.module';
import { AvatarComponent } from './avatar/avatar.component';
import { BannerCarouselComponent } from './banner-carousel/banner-carousel.component';
import { ContentScrollerComponent } from './content-scroller/content-scroller.component';
import { ContentGridComponent } from './content-grid/content-grid.component';
import { EpisodeGridComponent } from './episode-grid/episode-grid.component';
import { FavouriteGridComponent } from './favourite-grid/favourite-grid.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { ImageUploadComponent } from './image-upload/image-upload.component';
import { ImageCropperDialogComponent } from './image-upload/image-cropper-dialog/image-cropper-dialog.component';
import { ImageCropperComponent } from './image-cropper/image-cropper.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { SearchGridComponent } from './search-grid/search-grid.component';



@NgModule({
  declarations: [
    SearchBarComponent,
    AvatarComponent,
    BannerCarouselComponent,
    ContentScrollerComponent,
    ContentGridComponent,
    EpisodeGridComponent,
    FavouriteGridComponent,
    DatePickerComponent,
    ImageUploadComponent,
    ImageCropperDialogComponent,
    ImageCropperComponent,
    LoadingSpinnerComponent,
    SearchGridComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [
    SearchBarComponent,
    AvatarComponent,
    BannerCarouselComponent,
    ContentScrollerComponent,
    ContentGridComponent,
    EpisodeGridComponent,
    FavouriteGridComponent,
    DatePickerComponent,
    ImageUploadComponent,
    ImageCropperDialogComponent,
    ImageCropperComponent,
    LoadingSpinnerComponent,
    SearchGridComponent
  ],
  entryComponents: [ImageCropperDialogComponent]
})
export class UiElementsModule { }
