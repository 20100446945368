import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-onnet-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.sass']
})
export class LoadingSpinnerComponent implements OnInit {

  @Input() type: string;

  @Input() sizeConfig: string;

  sizeConfigs = [
    { id: 1, value: 'table' },
    { id: 2, value: 'image' },
    { id: 3, value: 'button' },
    { id: 4, value: 'page' }
  ];

  selectedConfig: string;

  constructor() { }

  ngOnInit() {
    this.sizeConfigs.forEach(config => {
      if (config.value === this.sizeConfig) {
        this.selectedConfig = config.value;
      }
    });
  }

}
