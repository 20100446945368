import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ContentVO } from 'src/app/models/content-vo';
import { ApiService } from 'src/app/utility/global-services/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { GlobalConstants } from 'src/app/utility/global-constants/global-constants';
import { AssetInfo } from 'src/app/models/asset-info';
import { HelperFunctionsService } from 'src/app/utility/global-services/helper-functions.service';
import { ConfigService } from 'src/app/utility/global-services/config.service';

@Component({
  selector: 'app-onnet-search-grid',
  templateUrl: './search-grid.component.html',
  styleUrls: ['./search-grid.component.sass']
})
export class SearchGridComponent implements OnInit {

  constructor(
    private apiService: ApiService,
    private snackBar: MatSnackBar,
    private route: Router,
    private helperService: HelperFunctionsService,
    private config : ConfigService
  ) { }

  contentsPerPage = 5;

  pathParams: string;

  contentType: string;
  throttle = 100;
  scrollDistance = 1;
  disableInfiniteScroll = false;

  @Input() contents: AssetInfo[] = [];

  @Output() scrollDownEvent = new EventEmitter<any>();

  ngOnInit() {
    const parent = this;
    function responsiveChanges() {
      const w = window.innerWidth;
      if (w > 1200) {
        parent.contentsPerPage = 5;
      } else if (w <= 1200 && w > 900) {
        parent.contentsPerPage = 4;
      } else if (w <= 900) {
        parent.contentsPerPage = 3;
      }
    }

    window.addEventListener('resize', responsiveChanges);
    responsiveChanges();
  }

  getThumbnail(content: AssetInfo) {
    let url;
    if (content.thumbnailInformation && content.thumbnailInformation.length > 0) {
      if (content.thumbnailInformation[0].orient === 0) {
        url = content.thumbnailInformation[0].url;
      } else if (content.thumbnailInformation[1] && content.thumbnailInformation[1].orient === 0) {
        url = content.thumbnailInformation[1].url;
      }
    }
    return url;
  }

  getContentThumbnail(content: ContentVO) {
    // "./assets/images/sample1.jpg"
    let url = './assets/images/thumbnail-default.jpg';
    if (content.thumbnailInformation && content.thumbnailInformation.length > 0) {
      if (content.thumbnailInformation[0].orient === 0) {
        url = content.thumbnailInformation[0].url;
      } else if (content.thumbnailInformation[1].orient
        && content.thumbnailInformation[1].orient === 0) {
        url = content.thumbnailInformation[1].url;
      }
    }
    return url;
  }

  selectContent(content: AssetInfo) {
    const url = this.resolvePath(content);
    const subscriberId = window.localStorage.getItem('subscriberId');
    const body = { id: content.id, subscriberId };
    this.apiService.post(url, body).subscribe(
      (res) => {
        const contentInfo = res.data;
        window.localStorage.setItem(this.contentType, JSON.stringify(contentInfo));
        window.localStorage.setItem(this.contentType + 'VO', JSON.stringify(content));
        // window.localStorage.setItem('clickType', clickType);
        this.route.navigate(['content', this.pathParams, content.id]);
      }, (err) => {
        this.snackBar.open('Failed to load data', 'close', { duration: 3000 });
        // window.localStorage.removeItem('clickType');
      });
  }

  resolvePath(content: AssetInfo) {
    // if (content.contentType === 0) {
    this.pathParams = 'asset';
    this.contentType = GlobalConstants.asset;
    return this.config.json.apiIpPortValue + 'asset/getAssetInfoById.on';
    // } else if (content.contentType === 1) {
    //   this.pathParams = 'sub';
    //   this.contentType = GlobalConstants.subCategory;
    //   return this.config.json.apiIpPortValue + 'category/getCategoryInfoById.on';
    // } else if (content.contentType === 2) {
    //   this.pathParams = 'series';
    //   this.contentType = GlobalConstants.series;
    //   return this.config.json.apiIpPortValue + 'series/getSeriesInfoById.on';
    // } else if (content.contentType === 3) {
    //   this.pathParams = 'live';
    //   this.contentType = GlobalConstants.live;
    //   return this.config.json.apiIpPortValue + 'channel/getChannelInfoById.on';
    // }
  }


  playContent(event, content: AssetInfo) {
    event.stopPropagation();
    window.localStorage.setItem('clickType', GlobalConstants.playContent);
    this.selectContent(content);
  }

  loadContent(content: AssetInfo) {
    window.localStorage.setItem('clickType', GlobalConstants.pageContent);
    this.selectContent(content);
  }


  // Mouse hover effects
  addHoverClass(e) {
    e.currentTarget.parentElement.parentElement.classList.add('hover');
    const parts = e.currentTarget.classList[1].split('-');
    const thumbnailPosition = Number(parts[parts.length - 1]);


    if ((thumbnailPosition % this.contentsPerPage === 0)) {
      e.currentTarget.parentElement.parentElement.classList.add('hover-left');
    } else if (((thumbnailPosition - 1) % this.contentsPerPage === 0)) {
      e.currentTarget.parentElement.parentElement.classList.add('hover-right');
    } else {
      e.currentTarget.parentElement.parentElement.classList.add('hover-center');
    }
  }


  removeHoverClass(e) {
    e.currentTarget.parentElement.parentElement.classList.remove('hover');
    e.currentTarget.parentElement.parentElement.classList.remove('hover-left');
    e.currentTarget.parentElement.parentElement.classList.remove('hover-right');
    e.currentTarget.parentElement.parentElement.classList.remove('hover-center');
  }


  addToFavourites(event, content: AssetInfo) {
    event.stopPropagation();

    const url = this.config.json.apiIpPortValue + 'favourites/setAsFavourites.on';
    const body = this.addToFavouritesResponseBody(this.helperService.createContentVOFromAssetList([content])[0]);
    this.apiService.post(url, body).subscribe(
      (res) => {
        content.favourite = true;
        this.snackBar.open('Added to favorites!', 'close', { duration: 4000 });
      },
      (err) => {
        this.snackBar.open('Unable to add to favorite', 'close', { duration: 4000 });
      }
    );
  }

  removeFromFavourites(event, content: AssetInfo) {
    event.stopPropagation();

    const url = this.config.json.apiIpPortValue + 'favourites/deleteFavourites.on';
    const body = this.removeFromFavouritesRequestBody(this.helperService.createContentVOFromAssetList([content])[0]);
    this.apiService.post(url, body).subscribe(
      (res) => {
        content.favourite = false;
        this.snackBar.open('Removed from favorites!', 'close', { duration: 4000 });
      },
      (err) => {
        this.snackBar.open('Unable to remove', 'close', { duration: 4000 });
      }
    );
  }

  addToFavouritesResponseBody(content: ContentVO) {
    const obj = {
      contentInformation: [content]
    };
    return obj;
  }

  removeFromFavouritesRequestBody(content: ContentVO) {
    const obj = {
      subCategoryId: content.subCategoryId
    };
    return obj;
  }

  onScrollDown() {
    this.scrollDownEvent.emit(true);
  }

}
