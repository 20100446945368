import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

/**
 * Service for calling various REST methods. Initialized at root level.
 */
@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private httpClient: HttpClient) { }

  get(path): Observable<any> {
    const header = new HttpHeaders()
      .set('content-type', 'application/json');
    const options = { headers: header };
    return this.httpClient.get(path, options);
  }

  post(path, body): Observable<any> {
    const header = new HttpHeaders()
      .set('content-type', 'application/json');
    const options = { headers: header };
    return this.httpClient.post(path, body, options);
  }

  put(path, body): Observable<any> {
    const header = new HttpHeaders()
      .set('content-type', 'application/json');
    const options = { headers: header };
    return this.httpClient.put(path, body, options);
  }

  // Delete method with the body appended to the options of the request
  delete(path, body?): Observable<any> {
    const header = new HttpHeaders()
      .set('content-type', 'application/json');
    const options = { headers: header, body: { body } };
    return this.httpClient.delete(path, options);
  }

  getIp(path): Observable<any> {
    const header = new HttpHeaders()
      .set('content-type', 'application/json')
      .set('Access-Control-Allow-Methods', 'GET,PUT,POST,DELETE,PATCH,OPTIONS');
    const options = { headers: header };
    return this.httpClient.get(path, options);
  }

}
