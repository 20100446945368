import { TreeviewItem } from 'ngx-treeview';

export class GlobalConstants {


  // video js player extensions
  public static m3u8 = 'application/x-mpegURL';
  public static mp4 = 'video/mp4';
  public static flv = 'video/x-flv';
  public static gp = 'video/3gpp';
  public static mkv = 'video/x-matroska';
  // public static mkv = 'video/webm';
  public static ogv = 'video/ogg';
  public static mov = 'video/mp4';
  public static m4v = 'video/mp4';

  // Default images
  public static bannerUrlDefault = './assets/images/banner_default.jpg';

  public static clearAll = 'clearAll';
  public static success = 'success';
  public static failure = 'failure';

  // Ellipsis dropdown menu
  public static edit = 'edit';
  public static delete = 'delete';

  // Image type
  public static landscape = 'landscape';
  public static portrait = 'portrait';
  public static banner = 'banner';

  // Image type code
  public static landscapeCode = 0;
  public static portraitCode = 1;

  // Sorting order mapping
  public static defaultOrder = 0;
  public static ascending = 1;
  public static descending = 2;

  public static topCategory = 'topCategory';
  public static subCategory = 'subCategory';
  public static asset = 'asset';
  public static series = 'series';
  public static live = 'live';

  public static playContent = 'playClick';
  public static pageContent = 'pageClick';



  // Category selection
  public static topLevel = 'topLevel';
  public static subLevel = 'subLevel';

  // Series selection
  public static seriesSeasons = 'seriesSeasons';
  public static seriesEpisodes = 'seriesEpisodes';

  // Tree structure
  public static panel = 'panel';
  public static dropdown = 'dropdown';

  // Category add content popup tab names
  public static Content = 'Content';
  public static VOD = 'VOD';
  public static Series = 'Series';
  public static SubCategory = 'Sub-Category';
  public static Live = 'Live';

  // Default tree view item
  public static defaultTreeView = [
    new TreeviewItem({
      text: 'IT', value: 9, checked: false, collapsed: true, children: [
        {
          text: 'Programming', value: 91, collapsed: true, checked: false, children: [{
            text: 'Frontend', value: 911, collapsed: true, children: [
              { text: 'Angular 1', value: 9111, collapsed: true, checked: false },
              { text: 'Angular 2', value: 9112, collapsed: true, checked: false },
              { text: 'ReactJS', value: 9113, collapsed: true, checked: false }
            ]
          }, {
            text: 'Backend', value: 912, checked: false, collapsed: true, children: [
              { text: 'C#', value: 9121, collapsed: true, checked: false },
              { text: 'Java', value: 9122, collapsed: true, checked: false },
              { text: 'Python', value: 9123, collapsed: true, checked: false }
            ]
          }]
        },
        {
          text: 'Networking', value: 92, checked: false, collapsed: true, children: [
            { text: 'Internet', value: 921, collapsed: true, checked: false },
            { text: 'Security', value: 922, collapsed: true, checked: false }
          ]
        }
      ]
    })
  ];

  // Group of variables denoting the type of sidebar
  public static contentSidebar = 'contentSidebar';
  public static usersSidebar = 'usersSidebar';
  public static statisticsSidebar = 'statisticsSidebar';
  public static settingsSidebar = 'settingsSidebar';


  public static twelveToTwentyFour = 'twelveToTwentyFour';
  public static twentyFourToTwelve = 'twentyFourToTwelve';

  public static schedule = 'schedule';
  public static always = 'always';

  // Pagination options
  public static paginationSizeOptions = [1, 2, 5, 10, 25, 100];

  // Server types
  public static streamEngine = 'Stream Engine';
  public static encoderTranscoder = 'Encoder/Transcoder';

  public static pathValue = {
    0: 'asset',
    1: 'category',
    2: 'series',
    3: 'channel',
    4: 'home',
    5: 'favorites',
    6: 'search'
  };

  public static defaultProfileImage = './assets/images/profile-default.jpg';

}
