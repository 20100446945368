
import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { CategoryInformation } from 'src/app/models/category-information';

@Injectable({
  providedIn: 'root'
})
export class SubjectService {

  constructor() { }

  public masonryImageSubject = new Subject<any>();

  public currentBannerSlide = new Subject<any>();

  public sidebarSubject = new Subject<any>();

  public topNavSubject = new Subject<CategoryInformation>();

  public topNavActiveSubject = new Subject<boolean>();

  private searchValueSubject = new Subject<string>();
  private clearSearchValueSubject = new Subject<string>();

  public loggedInSubject = new Subject<boolean>();

  public assetOnSameNavigation = new Subject<any>();

  public categoryOnSameNavigation = new Subject<any>();

  public seriesOnSameNavigation = new Subject<any>();

  public liveOnSameNavigation = new Subject<any>();

  public profileImageSubject = new Subject<string>();


  // ==================================================================================================================
  // FUNCTIONS TO EMIT VALUES FROM VARIOUS SUBJECTS
  // ==================================================================================================================

  setMasonryImagesSubject(masonryImages) {
    return this.masonryImageSubject.next(masonryImages);
  }

  setCurrentBannerSlide(slide) {
    return this.currentBannerSlide.next(slide);
  }

  setSidebarSubject(navItem: string) {
    return this.sidebarSubject.next(navItem);
  }

  setTopNavSubject(category: CategoryInformation) {
    return this.topNavSubject.next(category);
  }

  getTopNavSubject() {
    return this.topNavSubject.asObservable();
  }

  setSearchValueSubject(searchValue: string) {
    return this.searchValueSubject.next(searchValue);
  }
  getSearchValueSubject(): Observable<any> {
    return this.searchValueSubject.asObservable();
  }

  // Getters and setters for clearing the search value subject
  setClearSearchValueSubject(value: string) {
    return this.clearSearchValueSubject.next(value);
  }
  getClearSearchValueSubject() {
    return this.clearSearchValueSubject.asObservable();
  }

  setTopNavActiveSubject(status: boolean) {
    return this.topNavActiveSubject.next(status);
  }

  setLoggedInSubject(status: boolean) {
    return this.loggedInSubject.next(status);
  }

  // Asset Subject Setter and Getter methods
  setAssetOnSameNavigation(value: any) {
    return this.assetOnSameNavigation.next(value);
  }

  getAssetOnSameNavigation(): Observable<any> {
    return this.assetOnSameNavigation.asObservable();
  }

  // Category Subject Setter and Getter methods
  setCategoryOnSameNavigation(value: any) {
    return this.categoryOnSameNavigation.next(value);
  }
  getCategoryOnSameNavigation(): Observable<any> {
    return this.categoryOnSameNavigation.asObservable();
  }

  // Series Subject Setter and Getter methods
  setSeriesOnSameNavigation(value: any) {
    return this.seriesOnSameNavigation.next(value);
  }
  getSeriesOnSameNavigation(): Observable<any> {
    return this.seriesOnSameNavigation.asObservable();
  }

  // Live Subject Setter and Getter methods
  setLiveOnSameNavigation(value: any) {
    return this.liveOnSameNavigation.next(value);
  }
  getLiveOnSameNavigation(): Observable<any> {
    return this.liveOnSameNavigation.asObservable();
  }

  // Getters and Setters for Profile Image Url
  setProfileImageSubject(url: string) {
    return this.profileImageSubject.next(url);
  }
  getProfileImageSubject(): Observable<string> {
    return this.profileImageSubject.asObservable();
  }

}
