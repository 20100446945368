import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material/material.module';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ModifyDurationPipe } from './pipe/modify-duration.pipe';
import { DeviceDetectorModule } from 'ngx-device-detector';


@NgModule({
  declarations: [ModifyDurationPipe],
  imports: [
    CommonModule,
    MaterialModule,
    AngularSvgIconModule,
    ReactiveFormsModule,
    FormsModule,
    InfiniteScrollModule,
    DeviceDetectorModule.forRoot(),
  ],
  exports: [
    MaterialModule,
    AngularSvgIconModule,
    ReactiveFormsModule,
    FormsModule,
    InfiniteScrollModule,
    DeviceDetectorModule,

    ModifyDurationPipe
  ]
})
export class SharedModule { }
