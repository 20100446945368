import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopnavComponent } from './topnav/topnav.component';
import { SharedModule } from '../shared/shared.module';
import { UiElementsModule } from '../ui-elements/ui-elements.module';



@NgModule({
  declarations: [TopnavComponent],
  imports: [
    CommonModule,
    SharedModule,
    UiElementsModule
  ],
  exports: [TopnavComponent]
})
export class NavigationModule { }
