import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthenticateService {

  constructor(
    private router: Router
  ) { }

  pathValidity(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (window.localStorage.getItem('subscriberAuthToken')) {
      return true;
    } else {
      this.router.navigate(['auth', 'login']);
      return false;
    }
  }
}
