import { Component, OnInit, HostListener } from '@angular/core';
import { SubjectService } from 'src/app/utility/global-services/subject.service';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { HelperFunctionsService } from 'src/app/utility/global-services/helper-functions.service';
import { StatisticsDataCollectorService } from 'src/app/utility/global-services/statistics-data-collector.service';

@Component({
  selector: 'app-core',
  templateUrl: './core.component.html',
  styleUrls: ['./core.component.sass']
})
export class CoreComponent implements OnInit {

  constructor(
    private subjectService: SubjectService,
    private helper: HelperFunctionsService,
    private router: Router,
    private stat: StatisticsDataCollectorService
  ) { }

  navigationUrl: string;

  hideSidenavBar = false;

  subscriberIsLoggedIn = false;

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    // window.history.forward();
    // this.router.navigate(['content/favorites']);
    this.helper.navigateBack();
  }

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.getFullPathUrl(event);
        this.emitTopNavActiveStatus();
        // if (window.localStorage.getItem('subscriberAuthToken')) {
        //   this.router.navigate(['content']);
        // } else if (this.navigationUrl.includes('auth/signup')) {
        //   this.router.navigate(['auth', 'signup']);
        // } else {
        //   this.router.navigate(['auth', 'login']);
        // }
      }
    });

    this.subjectService.loggedInSubject.subscribe(value => {
      this.subscriberIsLoggedIn = value;
      window.localStorage.setItem('subscriberIsLoggedIn', value.toString());
    });

    if (window.localStorage.getItem('subscriberIsLoggedIn')) {
      this.subscriberIsLoggedIn = window.localStorage.getItem('subscriberIsLoggedIn') === 'true' ? true : false;
    }



  }

  /**
   * Get the navigation url from Navigation data
   * @param event NavigationEnd type contains navigation data
   */
  private getFullPathUrl(event) {
    this.navigationUrl = event.url;
  }

  // To enable active status for the top-nav bar elements
  private emitTopNavActiveStatus() {
    if (this.navigationUrl.includes('content/home')) {
      this.subjectService.setTopNavActiveSubject(true);
    } else {
      this.subjectService.setTopNavActiveSubject(false);
    }
  }

  onScroll() {

  }

}
