import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { ImageCropperComponent } from '../../image-cropper/image-cropper.component';
import { ImageCroppedEvent } from '../../image-cropper/interfaces';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-image-cropper-dialog',
  templateUrl: './image-cropper-dialog.component.html',
  styleUrls: ['./image-cropper-dialog.component.sass']
})
export class ImageCropperDialogComponent implements OnInit {


  constructor(
    public dialogRef: MatDialogRef<ImageCropperDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  @ViewChild(ImageCropperComponent, { static: false }) imageCropper: ImageCropperComponent;

  imageChangedEvent: any = '';
  croppedImage: any = '';
  showCropper = false;
  imageUploadFileEvent: any = '';
  imageName = '';
  displayImage: any = '';
  aspectRatio: any;

  ngOnInit() {
    this.croppedImage = this.data.image;
    // this.imageChangedEvent = this.data;
    this.imageUploadFileEvent = this.data.image;
    this.imageName = this.data.image.name;
    this.aspectRatio = this.data.aspectRatio;
  }

  convertBlobToFile(data) {
    // const blob = new Blob([data], { type: 'image/jpeg' });
    const file = new File([data], this.imageName);
    return file;
    // const bin = atob(data);
    // const ar = [];
  }

  imageCropped(event: ImageCroppedEvent) {
    const file = event.file;
    this.displayImage = event.base64;
    this.croppedImage = this.convertBlobToFile(file);
    //console.log(event);
  }

  imageLoaded() {
    this.showCropper = true;
    //console.log('Image loaded');
  }
  cropperReady() {
    //console.log('Cropper ready');
  }
  loadImageFailed() {
    //console.log('Load failed');
  }
  rotateLeft() {
    this.imageCropper.rotateLeft();
  }
  rotateRight() {
    this.imageCropper.rotateRight();
  }
  flipHorizontal() {
    this.imageCropper.flipHorizontal();
  }
  flipVertical() {
    this.imageCropper.flipVertical();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
