import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { ApiService } from 'src/app/utility/global-services/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CategoryInformation } from 'src/app/models/category-information';
import { Category } from 'src/app/models/category';
import { NavigationEnd, Router } from '@angular/router';
import { SubjectService } from 'src/app/utility/global-services/subject.service';
import { HelperFunctionsService } from 'src/app/utility/global-services/helper-functions.service';
import { SubscriberInfo } from 'src/app/models/subscriber-info';
import { Subscription } from 'rxjs';
import { GlobalConstants } from 'src/app/utility/global-constants/global-constants';
import { ConfigService } from 'src/app/utility/global-services/config.service';

@Component({
  selector: 'app-topnav',
  templateUrl: './topnav.component.html',
  styleUrls: ['./topnav.component.sass']
})
export class TopnavComponent implements OnInit, AfterViewInit, OnDestroy {
isMobile() {
throw new Error('Method not implemented.');
}
  isMenuActive: boolean;
  isPlan: boolean = false

authService: any;
  constructor(
    private service: ApiService,
    private snakBar: MatSnackBar,
    private router: Router,
    private config: ConfigService,
    private subjectService: SubjectService

  )  {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isPlan = this.router.url.includes('/plans');
        console.log(this.isPlan)
      }
    });
  }

  /**
   * Stores the path for avatar image, fetched from DB
   */
  imagePath = GlobalConstants.defaultProfileImage;

  /**
   * List of menu items to be displayed when we click avatar image
   *
   * Contains list of objects having 3 properties - menu item name, icon path and path for routing
   */
  menuList = [
    { name: 'Login', iconPath: './assets/icons/logout.svg', routerPath: '' },
  ];
  menuListForSubscribers = [
    { name: 'Profile', iconPath: './assets/icons/user.svg', routerPath: '' },
    { name: 'Logout', iconPath: './assets/icons/logout.svg', routerPath: '' }
  ];
  /**
   * List of top navigation elements
   */
  // logout(){
  //   this.router.navigate(['/auth/logout']);
  // }
  navList = [];
  /**
   * Copy of navlist (without reference)
   */
  navListCopy = this.navList.slice();

  /**
   * "More" navigation dropdown when navigation list exceeds set width
   */
  moreNavList = [];

  // if user click on some other category (home,movies etc)
  /*   topNavClicked: boolean; */

  // to store top level cateogries id, name and content-type
  topLevelCategories: Category[] = [];

  activeNav = 'Home';

  showTopNavActive = true;

  subscriberName = 'Subscriber';

  profileImageSubscription: Subscription;
  logoUrlFromConfig
  isGuest
  ngOnInit() {
    this.isGuest = window.localStorage.getItem('isGuest');
    this.logoUrlFromConfig = this.config.json.logoFromS3;
    this.loadMenuInfo();

    this.subjectService.topNavActiveSubject.subscribe(value => {
      this.showTopNavActive = value;
    });
    if (window.localStorage.getItem('subscriberInfo')) {
      const subscriber: SubscriberInfo = JSON.parse(window.localStorage.getItem('subscriberInfo'));
      this.subscriberName = subscriber.subscriberName ? subscriber.subscriberName : this.subscriberName;
      this.imagePath = subscriber.profilePictureUrl ? subscriber.profilePictureUrl : this.imagePath;
    }

    // this.subjectService.profileImageSubject.subscribe(value => {
    //   this.imagePath = value;
    // });

    this.profileImageSubscription = this.subjectService.getProfileImageSubject().subscribe(url => this.imagePath = url);
    this.setProfileImage();

  }

  setProfileImage() {
    const imageUrl = window.localStorage.getItem('profileImage');
    this.imagePath = imageUrl && imageUrl !== '' ? imageUrl : this.imagePath;
  }

  // loadMenuInfo() {
  //   window.localStorage.removeItem('topLevelMenuInfo');
  //   window.localStorage.removeItem('topLevelCategoriesInfo');

  //   console.log("after remove item topLevelCategories length:",JSON.stringify(this.topLevelCategories).length);
   
  //   const topLevelUrl = this.config.json.apiIpPortValue + 'category/getTopCategories.on';
  //   this.service.get(topLevelUrl).subscribe(
  //     (res) => {
  //       this.topLevelCategories = this.setToplevelCategories(res.data);
  //       // this.orderBySequenceNumber();
  //       this.navList = this.topLevelCategories;
  //       try{
  //         window.localStorage.setItem('topLevelCategoriesInfo', JSON.stringify(this.topLevelCategories));
  //         window.localStorage.setItem('topLevelMenuInfo', JSON.stringify(this.topLevelCategories[0]));
  //       }catch(e){
  //         console.error('Failed to set items in localStorage:', e);
  //       }
  //       // //console.log(this.topLevelCategories);
  //       console.log("browser_storage_length:",JSON.stringify(this.topLevelCategories).length);
  //       if (this.topLevelCategories[0]) {
  //         setTimeout(() => {
  //           this.subjectService.setTopNavSubject(this.topLevelCategories[0]);
  //         }, 500);
  //       }
  //     }, (err) => {
  //       this.snakBar.open('Failed to get top level categories', 'close', { duration: 3000 });
  //     }
  //   );
  // }
  loadMenuInfo() {
    // Remove previous items
    //window.localStorage.removeItem('topLevelMenuInfo');
    //window.localStorage.removeItem('topLevelCategoriesInfo');

    const topLevelUrl = `${this.config.json.apiIpPortValue}category/getTopCategories.on`;

    this.service.get(topLevelUrl).subscribe(
        (res) => {
            this.topLevelCategories = this.setToplevelCategories(res.data);
            this.navList = this.topLevelCategories;

            // Log size of data before setting in localStorage
            console.log("Size of topLevelCategories:", JSON.stringify(this.topLevelCategories).length);

            try {
                // Check for valid data before setting it
                if (this.topLevelCategories.length > 0) {
                  
                    //window.localStorage.setItem('topLevelCategoriesInfo', JSON.stringify(this.topLevelCategories));
                    //window.localStorage.setItem('topLevelMenuInfo', JSON.stringify(this.topLevelCategories[0]));
                }
            } catch (e) {
                console.error('Failed to set items in localStorage:', e);
                if (e.name === 'QuotaExceededError') {
                    this.snakBar.open('Local storage quota exceeded!', 'close', { duration: 3000 });
                }
            }

            console.log("browser_storage_length:", JSON.stringify(this.topLevelCategories).length);
            // if (this.topLevelCategories[0]) {
            //     this.subjectService.setTopNavSubject(this.topLevelCategories[0]);
            // }

            if (this.topLevelCategories[0]) {
                      setTimeout(() => {
                        this.subjectService.setTopNavSubject(this.topLevelCategories[0]);
                      }, 500);
                    }
        },
        (err) => {
            this.snakBar.open('Failed to get top level categories', 'close', { duration: 3000 });
        }
    );
}

  orderBySequenceNumber() {
    const tempArr = this.topLevelCategories;
    tempArr.sort((a, b) => a.topCategorySequenceNumber - b.topCategorySequenceNumber);
    //console.log(tempArr);
  }

  goToFavouritesPage() {
    //console.log('Favourites page clicked');
    this.router.navigate(['content', 'favorites']);
  }

  // user clicked on any of the top menu
  navItemClicked(item: CategoryInformation) {

    if (item) {
      this.activeNav = item.categoryName;
      // this.helperService.clearNavigationData();
      this.router.navigate(['content', 'home']);
      setTimeout(() => {
        this.subjectService.setTopNavSubject(item);
      }, 250);
      this.toggleMenu(); // Close the menu after an item is clicked
    }
  }

  // to set top level cateogries id, name and content-type into topLevelCategories array
  setToplevelCategories(data: Category[]) {
    return data;
  }

  toggleMenu() {
    this.isMenuActive = !this.isMenuActive;
  }

  ngAfterViewInit() {
    const parent = this;
    let prevWidth;

    // let navElementWidth = [];
    // let navContainer = document.getElementById("nav");
    // for (let i = 0; i < parent.navListCopy.length; i++) {
    //   navElementWidth[i] = navContainer.children[i].clientWidth;
    // }

    function responsiveChanges() {

      let increasedWidth = false;
      const navContainer = document.getElementById('nav');
      const navContainerWidth = navContainer.clientWidth;

      if (window.innerWidth > prevWidth) {
        increasedWidth = true;
      } else {
        increasedWidth = false;
      }
      prevWidth = window.innerWidth;

      parent.moreNavList = [];
      for (let i = 0; i < navContainer.children.length; i++) {
        const navElement = navContainer.children[i] as HTMLElement;
        if (navElement.offsetLeft + navElement.clientWidth > navContainerWidth) {

          for (let j = 0; j < parent.navListCopy.length; j++) {
            if (navElement.innerText === parent.navListCopy[j]) {
              parent.navListCopy.splice(j, 1);
              if (parent.moreNavList.length === 0) {
                parent.navListCopy.splice(j - 1, 1);
              }
            }
          }

          parent.moreNavList.push(parent.navList[i]);
          if (parent.moreNavList.length === 1) {
            parent.moreNavList.push(parent.navList[i - 1]);
          }
        }
      }
    }

    window.addEventListener('resize', responsiveChanges);
    responsiveChanges();

    // Actions on scroll
    window.addEventListener('scroll', (e) => {
      const backgroundGradient = document.querySelector('.background-gradient') as HTMLElement;
      const background = document.querySelector('.background') as HTMLElement;
      if (window.scrollY > 50) {
        backgroundGradient.style.height = '42px';
        backgroundGradient.style.background = '#16161A';
        background.style.background = '#16161A';
        background.style.opacity = '0.9';
        backgroundGradient.style.opacity = '0.9';
      } else {
        background.style.background = '#1D1D27';
        background.style.opacity = '1';
        backgroundGradient.style.opacity = '1';
        backgroundGradient.style.height = '100px';
        // tslint:disable-next-line: max-line-length
        backgroundGradient.style.background = 'linear-gradient(180deg, rgba(29,29,39,1) 0%, rgba(29,29,39,0.49) 60%, rgba(29,29,39,0) 100%)' ||
          '-moz-linear-gradient(270deg, rgba(29,29,39,1) 0%, rgba(29,29,39,0.49) 60%, rgba(29,29,39,0) 100%)' ||
          // tslint:disable-next-line: max-line-length
          '-webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(29,29,39,1)), color-stop(60%, rgba(29,29,39,0.49)), color-stop(100%, rgba(29,29,39,0)))' ||
          '-webkit-linear-gradient(270deg, rgba(29,29,39,1) 0%, rgba(29,29,39,0.49) 60%, rgba(29,29,39,0) 100%)' ||
          '-o-linear-gradient(270deg, rgba(29,29,39,1) 0%, rgba(29,29,39,0.49) 60%, rgba(29,29,39,0) 100%)' ||
          '-ms-linear-gradient(270deg, rgba(29,29,39,1) 0%, rgba(29,29,39,0.49) 60%, rgba(29,29,39,0) 100%)';
      }
    });

  }

  ngOnDestroy() {
    window.localStorage.removeItem('topLevelCategoriesInfo');

    this.profileImageSubscription.unsubscribe();
  }

}




