import { Component, OnInit, Input, OnChanges, EventEmitter, Output } from '@angular/core';
import { ContentVO } from 'src/app/models/content-vo';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from 'src/app/utility/global-services/api.service';
import { ConfigService } from 'src/app/utility/global-services/config.service';

@Component({
  selector: 'app-onnet-episode-grid',
  templateUrl: './episode-grid.component.html',
  styleUrls: ['./episode-grid.component.sass']
})
export class EpisodeGridComponent implements OnInit, OnChanges {

  constructor(
    private snackBar: MatSnackBar,
    private apiService: ApiService,
    private config : ConfigService
  ) { }

  contentsPerPage = 5;
  previousContent;
  currentContent;

  @Input() contents = [];

  // Episode number which is playing currently
  @Input() episodePlaying = '';

  @Output() playContent = new EventEmitter<any>();

  ngOnInit() {
    const parent = this;
    function responsiveChanges() {
      const w = window.innerWidth;
      if (w > 1200) {
        parent.contentsPerPage = 5;
      } else if (w <= 1200 && w > 900) {
        parent.contentsPerPage = 4;
      } else if (w <= 900) {
        parent.contentsPerPage = 3;
      }
    }

    window.addEventListener('resize', responsiveChanges);
    responsiveChanges();
  }

  ngOnChanges() {
    // //console.log(this.episodePlaying);
  }

  getThumbnail(content: ContentVO) {
    const url = content.thumbnailInformation[0].orient === 0 ? content.thumbnailInformation[0].url :
      (content.thumbnailInformation[1] && content.thumbnailInformation[1].orient === 0 ? content.thumbnailInformation[1].url
        : undefined);
    return url;
  }

  getContentThumbnail(content: ContentVO) {
    // "./assets/images/sample1.jpg"
    let url = './assets/images/thumbnail-default.jpg';
    if (content.thumbnailInformation && content.thumbnailInformation.length > 0) {
      if (content.thumbnailInformation[0].orient === 0) {
        url = content.thumbnailInformation[0].url;
      } else if (content.thumbnailInformation[1].orient
        && content.thumbnailInformation[1].orient === 0) {
        url = content.thumbnailInformation[1].url;
      }
    }
    return url;
  }

  selectContent(content: ContentVO, status: boolean) {

    // Assign previous selected content to a variable
    this.previousContent = this.currentContent;
    // Assign the current content to another variable
    this.currentContent = content;

    // Check if content has been selected for first time
    if (this.previousContent) {
      // Check if previous content is same as current content
      if (this.previousContent.subCategoryId !== this.currentContent.subCategoryId) {
        // If not equal, then assign the current content id to the variable
        this.episodePlaying = this.currentContent.subCategoryId;
      } else {
        // Check if the current id value in the variable is same as id for current content
        if (this.episodePlaying === this.currentContent.subCategoryId) {
          // If same, then make the variable as empty
          this.episodePlaying = '';
        } else {
          // Else, assign the current content id to variable
          this.episodePlaying = this.currentContent.subCategoryId;
        }
      }
    } else {
      // Assign the current selected content id to the variable when selecting for first time
      this.episodePlaying = this.currentContent.subCategoryId;
    }


    const obj = { content, status };
    this.playContent.emit(obj);
  }

  loadContentInfo(id: string, status) {
    const url = this.config.json.apiIpPortValue + 'asset/getAssetInfoById.on';
    this.apiService.post(url, { id }).subscribe(
      (res) => {
        const contentInfo = res.data;
        const obj = { contentInfo, status };
        this.playContent.emit(contentInfo);
      },
      (err) => {
        this.snackBar.open('Unable to load content info', 'close', { duration: 4000 });

      }
    );
  }


  // Mouse hover effects
  addHoverClass(e) {
    e.currentTarget.parentElement.parentElement.classList.add('hover');
    const parts = e.currentTarget.parentElement.parentElement.classList[0].split('-');
    const thumbnailPosition = Number(parts[parts.length - 1]);


    if ((thumbnailPosition % this.contentsPerPage === 0)) {
      e.currentTarget.parentElement.parentElement.classList.add('hover-left');
    } else if (((thumbnailPosition - 1) % this.contentsPerPage === 0)) {
      e.currentTarget.parentElement.parentElement.classList.add('hover-right');
    } else {
      e.currentTarget.parentElement.parentElement.classList.add('hover-center');
    }
  }


  removeHoverClass(e) {
    e.currentTarget.parentElement.parentElement.classList.remove('hover');
    e.currentTarget.parentElement.parentElement.classList.remove('hover-left');
    e.currentTarget.parentElement.parentElement.classList.remove('hover-right');
    e.currentTarget.parentElement.parentElement.classList.remove('hover-center');
  }

}
