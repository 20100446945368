import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CanActivateRouteGuard } from '../utility/route-guards/can-activate.route.guard';
import { PlansComponent } from '../plans/plans.component';


const routes: Routes = [
  { path: '', redirectTo: 'content', pathMatch: 'full' },
  { path: 'plans', component: PlansComponent },
  {
    path: 'content', loadChildren: () => import('../content/content.module').then(m => m.ContentModule),
    canActivate: [CanActivateRouteGuard]
  },
  {
    path: 'profile', loadChildren: () => import('../profile/profile.module').then(m => m.ProfileModule),
    canActivate: [CanActivateRouteGuard]
  },
  { path: 'auth', loadChildren: () => import('../authentication/authentication.module').then(m => m.AuthenticationModule) }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CoreRoutingModule { }
