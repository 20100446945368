import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { SubjectService } from 'src/app/utility/global-services/subject.service';
import { HelperFunctionsService } from 'src/app/utility/global-services/helper-functions.service';
import { ApiService } from 'src/app/utility/global-services/api.service';
import { ConfigService } from 'src/app/utility/global-services/config.service';
import { MatSnackBar } from '@angular/material/snack-bar';

/**
 * Component for displaying profile image and dropdown menu actions
 */
@Component({
  selector: 'app-onnet-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.sass']
})
export class AvatarComponent implements OnInit {
isMobile() {
throw new Error('Method not implemented.');
}


  constructor(
    private apiService: ApiService,
    private config: ConfigService,
    private subjectService: SubjectService,
    private router: Router,
    private snackbar: MatSnackBar,

  ) { }

  /**
   * profile image path
   */
  @Input() imagePath: string;

  /**
   * List of menu items
   */
  @Input() menuList: [];

  /**
   * @ignore
   */

  /**
   * @ignore
   */
  ngOnInit() {
  }

  /**
   * Common method for menu item click action which resolves what action to be taken based on menu item name
   *
   * @param menuName Takes menu name as input for checking with menuList
   */
  menuItemAction(menuName: string) {
    if (menuName === 'Logout') {
      //console.log('Clicked on logout');
      this.logout();
    } else if (menuName === 'Profile') {
      //console.log('Clicked on others');
      this.router.navigate(['profile']);
    }
    else if (menuName === 'Login') {
      //console.log('Clicked on others');
      this.router.navigate(['auth', 'login']);
    }
  }

  // logout() {
  //   this.helper.clearData();
  //   this.subjectService.setLoggedInSubject(false);
  //   this.router.navigate(['auth', 'logout']);
  // }
  logout() {
    localStorage.clear()
    const url = this.config.json.apiIpPortValueNoAuth + 'auth/login.on';
    // const body = this.createBody();
    const body = {
      username: "guest@gmail.com",
      password: "guest@123"
    }
    if (body) {
      this.apiService.post(url, body).subscribe(
        (res) => {
          if (res.status != 'SUCCESS') {
            if (res.hasOwnProperty('message')) {
              this.snackbar.open(res.message, 'close', { duration: 5000 });

            }
          }
          window.localStorage.setItem('isGuest', 'true');
          console.log(res.data)
          window.localStorage.setItem('subscriberAuthToken', res.data.token);
          window.localStorage.setItem('subscriberInfo', JSON.stringify(res.data.currentUser));
          window.localStorage.setItem('subscriberId', res.data.currentUser.id);
          window.localStorage.setItem('subscriberEmail', res.data.currentUser.subscriberEmail);
          window.localStorage.setItem('profileImage', res.data.currentUser.profilePictureUrl ?
            res.data.currentUser.profilePictureUrl : '');
          this.subjectService.setLoggedInSubject(true);
          const url = 'content/home'
          this.router.navigateByUrl(url).then(() => {
            window.location.reload();
          });

        },
        (err) => {
          this.snackbar.open('Failed to login', 'close', { duration: 5000 });
        }
      );
    }

  }


}
