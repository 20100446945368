import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DeviceDetectorService } from 'ngx-device-detector';
import { StatisticsMedia } from 'src/app/models/statistics-media';
import { ApiService } from './api.service';
import { interval, EMPTY } from 'rxjs';
import { ThumbnailInfo } from 'src/app/models/thumbnail-info';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class StatisticsDataCollectorService {

  constructor(
    private httpClient: HttpClient,
    private deviceService: DeviceDetectorService,
    private apiService: ApiService,
    private config : ConfigService
  ) {
    this.getLocationData();
    const source = interval(10000);
    this.timerSubscription = source.subscribe(val => this.checkData());
  }

  eventQueue: StatisticsMedia[] = [];

  timerSubscription;

  locationData;

  timeout;
  duration = 10000;

  previousTime = 0;
  currentTime = 0;
  seekStart = null;

  cache = {};

  getLocationData() {
    this.httpClient.get('http://api.ipstack.com/check?access_key=bb7df651a6fd9a13512603815b3ad97a').subscribe(
      (res) => {
        this.locationData = res;
        // //console.log(res);
      },
      (err) => {
        //console.log('Error: ', err);
      }
    );
  }

  playEvent(contentId, contentName, contentDuration, contentType, thumbnail, startTime) {
    const stat = {} as StatisticsMedia;

    stat.subscriberId = window.localStorage.getItem('subscriberId');
    stat.contentId = contentId;
    stat.title = contentName;
    stat.contentDuration = contentDuration;
    stat.contentType = contentType;

    stat.thumbnail = this.getThumbnail(thumbnail);

    // stat.startTime = Math.round(this.player.cache_.currentTime);
    stat.startTime = startTime ? startTime : 0;
    stat.systemStartTime = new Date();

    const deviceInfo = this.deviceService.getDeviceInfo();
    // //console.log(deviceInfo);

    stat.osType = deviceInfo.os + '-' + deviceInfo.os_version;
    stat.browserType = deviceInfo.browser + '-' + deviceInfo.browser_version;
    stat.deviceType = 'PC';
    this.setConnectionData(stat);

    this.setLocationData(stat);

    stat.viewStatus = stat.startTime === 0 ? 1 : 0;

    this.eventQueue.push(stat);
    // //console.log(this.eventQueue);
  }

  pauseEvent(stopTime) {
    const stat = this.eventQueue.pop();
    // stat.stopTime = Math.round(this.player.cache_.currentTime);
    stat.stopTime = stopTime;

    stat.duration = Math.round(stat.stopTime) - Math.round(stat.startTime);

    stat.systemStopTime = new Date();

    this.eventQueue.push(stat);
    // //console.log(this.eventQueue);
  }

  seekEvent(stopTime) {
    const stat = this.eventQueue.pop();
    stat.stopTime = stopTime;

    stat.duration = stopTime - stat.startTime;

    this.eventQueue.push(stat);
    // //console.log(this.eventQueue);
  }


  findTimeDifference(startTime, endTime) {
    const start = new Date(startTime);
    const end = new Date(endTime);
    const diff = +end - +start;
    return diff / 1000;
  }

  setLocationData(stat: StatisticsMedia) {
    stat.geoLocation = this.locationData.region_name + '-' + this.locationData.country_name;
    stat.clientIp = this.locationData.ip;
  }

  setConnectionData(stat: StatisticsMedia) {
    const nav = navigator;
    // tslint:disable-next-line: no-string-literal
    stat.networkType = nav['connection'].effectiveType;
  }

  getThumbnail(thumbnail: ThumbnailInfo[]) {
    if (thumbnail[0].url) {
      return thumbnail[0].url;
    } else if (thumbnail[1].url) {
      return thumbnail[1].url;
    }
    return null;
  }

  saveData() {
    const url = this.config.json.apiIpPortValueStatistics + 'saveContentStat.on';
    const queue = this.getBody();
    const body = { contentStat: queue };
    const sub = this.apiService.post(url, body).subscribe(
      (res) => {
        //console.log(res);
      },
      (err) => {
        //console.log(err);
      }
    );
  }

  getBody() {
    const queue = this.eventQueue;
    const ele = queue.pop();
    this.eventQueue = [ele];

    return queue;
  }

  checkData() {
    if (this.eventQueue.length > 1) {
      this.saveData();
      // //console.log(this.eventQueue);
    }
  }
}
