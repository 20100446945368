import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";



@Injectable({
    providedIn: 'root'
})
export class ConfigService {
    json: any;

    constructor(
        private http: HttpClient
    ) { }

    loadConfig() {
        return this.http.get('../../../assets/config/config.json').toPromise().then(config => this.json = config);
    }
}
