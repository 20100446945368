import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'modifyDuration'
})
export class ModifyDurationPipe implements PipeTransform {

  transform(input: number): any {
    // const value = parseInt(input, 10);
    const value = input;
    let time = value;
    if (value < 60) {
      time = 1;
    } else if (value > 60) {
      time = Math.ceil(value / 60);
    }
    return time + ' min';
  }

}
