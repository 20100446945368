import { Injectable } from '@angular/core';

/**
 * Service which emulates window.localstorage functionality. Initialized at root level and can be used throughout the application
 */
@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  map = new Map();

  has(key) {
    return this.map.has(key);
  }

  get(key) {
    return this.map.get(key);
  }

  set(key, value: any) {
    this.map.delete(key);
    this.map.set(key, value);
  }

  delete(key) {
    this.map.delete(key);
  }

  clear() {
    this.map.clear();
  }
}
