import { Component, OnInit, ElementRef, ViewChild, Input, OnChanges, OnDestroy } from '@angular/core';
import { BannerUrls } from 'src/app/models/banner-url';
import { GlobalConstants } from 'src/app/utility/global-constants/global-constants';
import { ApiService } from 'src/app/utility/global-services/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ConfigService } from 'src/app/utility/global-services/config.service';
import { Category } from 'src/app/models/category';
import { HelperFunctionsService } from 'src/app/utility/global-services/helper-functions.service';
import { SubjectService } from 'src/app/utility/global-services/subject.service';

@Component({
  selector: 'app-onnet-banner-carousel',
  templateUrl: './banner-carousel.component.html',
  styleUrls: ['./banner-carousel.component.sass']
})
export class BannerCarouselComponent implements OnInit, OnChanges, OnDestroy {
  constructor(
    private service: ApiService,
    private snakBar: MatSnackBar,
    private subjectService: SubjectService,
    private route: Router,
    private config: ConfigService,
    private apiService: ApiService
  ) { }

  @ViewChild('bannerCarouselList', { static: false }) private bannerList: ElementRef;

  bannerCount = 0;
  totalBannerImages = 1;
  autoSlide = true;
  autoSlideDuration = 4000;  // in milliseconds
  currentBannerImages = 1;
  autoSlider;

  pathParams: string;
  contentType: string;
  isMouseOver: boolean = false;
  pauseSlide: boolean = false;
  isVideoEnded: boolean = false;

  contentInfo: any[] = [];
  previewUrlIds: any[] = [];

  @Input() images: BannerUrls[] = [];
  categories: any[] = [];

  previewUrl: string = '';

  // ngOnInit() {
  //   const url = this.config.json.apiIpPortValue + 'category/getCategoryInfoById.on';
  //   const info = { id: window.localStorage.getItem('topLevelMenuId') };
  //   this.service.post(url, info).subscribe(
  //     (res) => {
  //       this.categories = res.data.content.bannerUrl;
  //       for (let i = 0; i < this.categories.length; i++) {
  //         console.log(this.categories[i].bannerId);
  //       }
  //     },
  //     (err) => {
  //       // this.snakBar.open('Unable to fetch data.', 'close', { duration: 3000 });
  //     });
  // }


  ngOnInit(): void {
    setTimeout(() => {
      this.fetchCategoryInfo();
    }, 2000);
  }

  fetchCategoryInfo(): void {
    const url = this.config.json.apiIpPortValue + 'category/getCategoryInfoById.on';
    const info = { id: window.localStorage.getItem('topLevelMenuId') };
    this.service.post(url, info).subscribe(
      (res) => {
        this.categories = res.data.content.bannerUrl;
        for (let i = 0; i < this.categories.length; i++) {
          console.log(this.categories[i].bannerId);
        }
      },
      (err) => {
        // this.snakBar.open('Unable to fetch data.', 'close', { duration: 3000 });
      }
    );
  }

  ngOnChanges() {
    this.totalBannerImages = this.images && this.images.length ? this.images.length : 0;
    this.bannerCount = 0;
    window.localStorage.setItem('clickType','playClick')
    setTimeout(() => {
      this.hideOrShowButtons();
      this.indicatorActivation();
      this.slideBanner(0);
      this.isMouseOver = false;
      clearInterval(this.autoSlider);
      this.autoSlideBanner(this.autoSlide);
    }, 10);
  }

  next() {
    if (this.bannerCount < this.totalBannerImages - 1) {
      this.bannerCount++;
      this.slideBanner(this.bannerCount);
    }
    this.isMouseOver = false;
    this.hideOrShowButtons();
    this.indicatorActivation();
  }

  prev() {
    if (this.bannerCount > 0) {
      this.bannerCount--;
      this.slideBanner(this.bannerCount);
    }
    this.isMouseOver = false;
    this.hideOrShowButtons();
    this.indicatorActivation();
  }

  switchBanner(event) {
    const selectedBannerClass = event.target.classList[0];
    this.bannerCount = Number(selectedBannerClass[selectedBannerClass.length - 1]);
    this.slideBanner(this.bannerCount);
    this.hideOrShowButtons();
    this.indicatorActivation();
  }

  indicatorActivation() {
    if (this.bannerList && this.bannerList.nativeElement) {
      for (let i = 0; i < this.totalBannerImages; i++) {
        const indicator = this.bannerList.nativeElement.parentElement.querySelector(`.indicator${i}`);
        if (indicator) {
          if (i === this.bannerCount) {
            indicator.classList.add('active');
          } else {
            indicator.classList.remove('active');
          }
        }
      }
    }
  }

  slideBanner(bannerNumber) {
    this.bannerList.nativeElement.style.transform = `translateX(-${bannerNumber}00%)`;
  }

  hideOrShowButtons() {
    this.isMouseOver = false;
    if (this.bannerCount <= 0) {
      this.hideButton('prev-btn');
    }
    if (this.bannerCount < this.totalBannerImages - 1) {
      this.showButton('next-btn');
    }
    if (this.bannerCount >= this.totalBannerImages - 1) {
      this.hideButton('next-btn');
    }
    if (this.bannerCount > 0) {
      this.showButton('prev-btn');
    }
  }

  hideButton(btn) {
    this.bannerList.nativeElement.parentElement.querySelector(`#${btn}`).style.display = 'none';
  }

  showButton(btn) {
    this.bannerList.nativeElement.parentElement.querySelector(`#${btn}`).style.display = 'inline-block';
  }

  autoSlideBanner(autoSlide) {
    let resetPosition = false;
    if (autoSlide === true) {
      this.autoSlider = setInterval(() => {
        if (!this.pauseSlide) {
          if (this.bannerCount < this.totalBannerImages) {
            this.next();
          }
          if (this.bannerCount === this.totalBannerImages - 1) {
            if (resetPosition) {
              this.slideBanner(0);
              this.bannerCount = 0;
              this.hideOrShowButtons();
              this.indicatorActivation();
            }
            resetPosition = !resetPosition;
          } else {
            resetPosition = false;
          }
        }
        this.pauseSlide = true;
      }, this.autoSlideDuration);
    }
  }
  contentsPerPage = 5;
  addHoverClass(e) {
    e.currentTarget.parentElement.parentElement.classList.add('hover');
    const parts = e.currentTarget.classList[1].split('-');
    const thumbnailPosition = Number(parts[parts.length - 1]);
    if ((thumbnailPosition % this.contentsPerPage === 0)) {
      e.currentTarget.parentElement.parentElement.classList.add('hover-left');
    } else if (((thumbnailPosition - 1) % this.contentsPerPage === 0)) {
      e.currentTarget.parentElement.parentElement.classList.add('hover-right');
    } else {
      e.currentTarget.parentElement.parentElement.classList.add('hover-center');
    }
  }

  resetAutoSlideTime() {
    this.pauseSlide = false;
    this.isMouseOver = false;
  }

  loadContent(content: BannerUrls) {
    if (content.bannerId && content.bannerId !== '' && content.contentType !== undefined) {
      this.selectContent(content);
    }
  }

  selectContent(content: BannerUrls) {
    const url = this.resolvePath(content);
    this.apiService.post(url, { id: content.bannerId }).subscribe(
      (res) => {
        this.contentInfo = res.data;
        this.previewUrlIds = this.contentInfo['relatedContents']
        if (this.previewUrlIds && Array.isArray(this.previewUrlIds)) {
          this.previewUrlIds.forEach(ele => {
            console.log(ele.previewUrl);
          });
        }
        console.log(this.contentInfo);
        window.localStorage.setItem(this.contentType, JSON.stringify(this.contentInfo));
        window.localStorage.setItem('clickType', 'playClick');
        this.route.navigate(['content', this.pathParams, content.bannerId]);
      }, (err) => {
        // this.snackBar.open('Failed to load data', 'close', { duration: 3000 });
        window.localStorage.removeItem('clickType');
      });
  }

  resolvePath(content: BannerUrls) {
    console.log(this.config.json.apiIpPortValue);
    if (content.contentType === 0) {
      this.pathParams = 'asset';
      this.contentType = GlobalConstants.asset;
      return this.config.json.apiIpPortValue + 'asset/getAssetInfoById.on';
    } else if (content.contentType === 1) {
      this.pathParams = 'sub';
      this.contentType = GlobalConstants.subCategory;
      return this.config.json.apiIpPortValue + 'category/getCategoryInfoById.on';
    } else if (content.contentType === 2) {
      this.pathParams = 'series';
      this.contentType = GlobalConstants.series;
      return this.config.json.apiIpPortValue + 'series/getSeriesInfoById.on';
    } else if (content.contentType === 3) {
      this.pathParams = 'live';
      this.contentType = GlobalConstants.live;
      return this.config.json.apiIpPortValue + 'channel/getChannelInfoById.on';
    }
  }

  ngOnDestroy() {
    clearInterval(this.autoSlider);
  }

  onMouseEnter(content: BannerUrls) {  
    if (content.contentType === 0) {
      this.pauseSlide = true;
      setTimeout(() => {
        this.isMouseOver = true;
        let url = this.config.json.apiIpPortValue + 'asset/getAssetInfoById.on'
        const info = { id: content.bannerId }
        this.apiService.post(url, info).subscribe(
          (res) => {
            if (res.data) {
              this.previewUrl = res.data.content.previewUrl;
            }
          }
        );
      }, 2000);
    }
  }
  onMouseLeave(content: BannerUrls) {
    if (content.contentType ===0) {
      this.isMouseOver = false;
      this.pauseSlide = false;
      this.previewUrl = '';
    }
  }

  onVideoEnded() {
    this.previewUrl = '';
    if (this.isMouseOver) {
      window.scrollBy(0, 100);
    }
  }
}