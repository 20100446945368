import { Component, OnInit, ViewChild, ElementRef, Input, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import { ApiService } from 'src/app/utility/global-services/api.service';
import { SubjectService } from 'src/app/utility/global-services/subject.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigationExtras, Router } from '@angular/router';
import { Category } from 'src/app/models/category';
import { ContentVO } from 'src/app/models/content-vo';
import { GlobalConstants } from 'src/app/utility/global-constants/global-constants';
import { HelperFunctionsService } from 'src/app/utility/global-services/helper-functions.service';
import { ConfigService } from 'src/app/utility/global-services/config.service';
import { ThrowStmt } from '@angular/compiler';

@Component({
  selector: 'app-onnet-content-scroller',
  templateUrl: './content-scroller.component.html',
  styleUrls: ['./content-scroller.component.sass']
})
export class ContentScrollerComponent implements OnInit {

  constructor(
    private apiService: ApiService,
    private subjectService: SubjectService,
    private helperService: HelperFunctionsService,
    private snackBar: MatSnackBar,
    private config : ConfigService,
    private route: Router) { }

  @ViewChild('contentList', { static: false }) private contentList: ElementRef;

  sectionCount = 0;
  totalSectionCount = 1;
  totalThumbnailImages = 1;
  contentMarginRight = 16;
  contentsPerPage = 5;

  contentType: string;

  routePageName: string;

  @Input() contents = [];
  @Input() id = String;

  @Input() parent = {} as ContentVO;

  @Input() startNewVideo: boolean;
  @Input() data: any;
  videoPlayer: any;
  childrenInfo = [];


  ngOnInit() {
    const parent = this;
    function responsiveChanges() {
      const w = window.innerWidth;
      if (w > 1200) {
        parent.contentsPerPage = 5;
      } else if (w <= 1200 && w > 900) {
        parent.contentsPerPage = 4;
      } else if (w <= 900) {
        parent.contentsPerPage = 3;
      }
      parent.initialisation();
      // Preventing overflowing section number which will result in empty page
      if (parent.sectionCount >= parent.totalSectionCount) {
        const increaseCount = parent.sectionCount - parent.totalSectionCount;
        for (let i = 0; i <= increaseCount; i++) {
          parent.prev();
        }
      }
    }

    window.addEventListener('resize', responsiveChanges);
    responsiveChanges();

  }

  // ngOnChanges(changes : SimpleChanges) {
  //   console.log(this.startNewVideo);
  //   this.initialisation();
    
  // }

  // ngOnChanges(changes: SimpleChanges): void {
  //   if (changes.startNewVideo && changes.startNewVideo.currentValue) {
  //     const validIndex = this.findFirstNonZeroIndex();
  //     if (validIndex !== -1) {
  //       this.loadValues(this.contents[validIndex], 'playClick');
  //     } else {
  //       console.error('No valid non-zero index found in contents array.');
  //     }
  //   }
  // }

  // private findFirstNonZeroIndex(): number {
  //   for (let i = 1; i < this.contents.length; i++) {
  //     if (this.contents[i]) {
  //       return i;
  //     }
  //   }
  //   return -1; // Return -1 if no valid index found
  // }

//   ngOnChanges(changes: SimpleChanges) {
//     if (changes.startNewVideo && changes.startNewVideo.currentValue) {   
//       setTimeout(() => {
//         console.log(this.contents[0])
//           this.loadValues(this.contents[0], 'playClick');
//         }, 3000);
//     }
// }

  getContentThumbnail(content: ContentVO) {
    // "./assets/images/sample1.jpg"
    let url = './assets/images/thumbnail-default.jpg';
    if (content.thumbnailInformation && content.thumbnailInformation.length > 0) {
      if (content.thumbnailInformation[0].orient === 0) {
        url = content.thumbnailInformation[0].url;
      } else if (content.thumbnailInformation[1].orient
        && content.thumbnailInformation[1].orient === 0) {
        url = content.thumbnailInformation[1].url;
      }
    }
    return url;
  }

  // add to favourites clicked
  addToFavourites(event, content: ContentVO) {
    event.stopPropagation();
    // event.preventDefault();

    const url = this.config.json.apiIpPortValue + 'favourites/setAsFavourites.on';
    const body = this.addToFavouritesResponseBody(content);
    this.apiService.post(url, body).subscribe(
      (res) => {
        content.favourite = true;
        this.snackBar.open('Added to favorites!', 'close', { duration: 4000 });
      },
      (err) => {
        this.snackBar.open('Unable to add to favorite', 'close', { duration: 4000 });
      }
    );
  }

  removeFromFavourites(event, content: ContentVO) {
    event.stopPropagation();

    const url = this.config.json.apiIpPortValue + 'favourites/deleteFavourites.on';
    const body = this.removeFromFavouritesRequestBody(content);
    this.apiService.post(url, body).subscribe(
      (res) => {
        content.favourite = false;
        this.snackBar.open('Removed from favorites!', 'close', { duration: 4000 });
      },
      (err) => {
        this.snackBar.open('Unable to remove', 'close', { duration: 4000 });
      }
    );

  }


  initialisation() {
    this.totalThumbnailImages = this.contents.length;
    this.totalSectionCount = Math.ceil(this.totalThumbnailImages / this.contentsPerPage);

    setTimeout(() => {
      this.hideOrShowButtons();
    }, 10);
  }

  // children clicked
  childrenInfoClicked(event, item: any, clickType: string) {
    event.stopPropagation();
    this.loadValues(item, clickType);
  }

  loadValues(item: any, clickType: string) { 
    console.log(item) 
    const url = this.setRequestUrl(item);
    const subscriberId = window.localStorage.getItem('subscriberId');
     const body = {
      id: item.subCategoryId ? item.subCategoryId : item.subCategoryId,
      categoryId: this.parent.id ||this.id,
    };
    console.log(body)
    this.apiService.post(url, body).subscribe(
      (res) => {
        const data = res.data;
        window.localStorage.setItem(this.contentType, JSON.stringify(data));
        window.localStorage.setItem(this.contentType + 'VO', JSON.stringify(item));
        window.localStorage.setItem('clickType', clickType);
        this.helperService.setBreadCrumbs(this.parent);
        this.callAppropriateSubject(item);        
        if(item.id){
          this.route.navigate(['content', this.routePageName, item.id]);
        }
        else if(item.subCategoryId){
          this.route.navigate(['content', this.routePageName, item.subCategoryId]);
        }
      }, (err) => {
        this.snackBar.open('Failed to load data', 'close', { duration: 3000 });
      });
  }

  setRequestUrl(item: any) {
    if (item.contentType === 0) {
      this.routePageName = 'asset';
      this.contentType = GlobalConstants.asset;
      return this.config.json.apiIpPortValue + 'asset/getAssetInfoById.on';
    } else if (item.contentType === 1) {
      this.routePageName = 'sub';
      this.contentType = GlobalConstants.subCategory;
      return this.config.json.apiIpPortValue + 'category/getCategoryInfoById.on';
    } else if (item.contentType === 2) {
      this.routePageName = 'series';
      this.contentType = GlobalConstants.series;
      return this.config.json.apiIpPortValue + 'series/getSeriesInfoById.on';
    } else if (item.contentType === 3) {
      this.routePageName = 'live';
      this.contentType = GlobalConstants.live;
      return this.config.json.apiIpPortValue + 'channel/getChannelInfoById.on';
    }
  }

  callAppropriateSubject(content: ContentVO) {
    if (content.contentType === 0) {
      this.subjectService.setAssetOnSameNavigation(true);
    } else if (content.contentType === 1) {
      this.subjectService.setCategoryOnSameNavigation(true);
    } else if (content.contentType === 2) {
    } else if (content.contentType === 3) {
      this.subjectService.setLiveOnSameNavigation(true);
    }
  }

  /* playClicked(item: any) {
    //console.log("play clicked from main page");
    this.loadValues(item);
  } */


  next() {
    if (this.sectionCount < this.totalSectionCount - 1) {
      this.sectionCount++;
      this.slideBanner(this.sectionCount);
    }
    this.hideOrShowButtons();
  }

  prev() {
    if (this.sectionCount > 0) {
      this.sectionCount--;
      this.slideBanner(this.sectionCount);
    }
    this.hideOrShowButtons();
  }


  slideBanner(sectionNumber) {

    const lastSectionContents = this.totalThumbnailImages % this.contentsPerPage;
    const pageMarginSide = this.contentList.nativeElement.firstElementChild.offsetLeft;
    const transitionWidthOffset = 2 * (pageMarginSide) - this.contentMarginRight;

    // Transition for last section
    if (this.sectionCount === this.totalSectionCount - 1) {
      let emptyContentRatio = (this.contentsPerPage - lastSectionContents) / this.contentsPerPage;
      if (lastSectionContents === 0) {
        emptyContentRatio = 0;
      }
      // Simplified calculations for last section transition
      const transitionInPercentage = (emptyContentRatio * 100) - (this.sectionCount * 100);
      const transitionInPixel = (sectionNumber * transitionWidthOffset) + emptyContentRatio
        * (-2 * pageMarginSide + this.contentMarginRight);

      this.contentList.nativeElement.style.transform =
        `translateX(${transitionInPercentage}%) translateX(${transitionInPixel}px)`;
    } else {
      this.contentList.nativeElement.style.transform =
        `translateX(-${sectionNumber}00%) translateX(${sectionNumber * transitionWidthOffset}px)`;
    }
  }


  hideOrShowButtons() {
    if (this.sectionCount <= 0) {
      this.hideButton('prev-btn');
    }
    if (this.sectionCount < this.totalSectionCount - 1) {
      this.showButton('next-btn');
    }
    if (this.sectionCount >= this.totalSectionCount - 1) {
      this.hideButton('next-btn');
    }
    if (this.sectionCount > 0) {
      this.showButton('prev-btn');
    }

  }

  // hideButton(btn) {
  //   // set timout provided to prevent hover over thumbnail while horizontal scrolling
  //   setTimeout(() => {
  //     this.contentList.nativeElement.parentElement.querySelector(`.${btn}`).style.display = 'none';
  //   }, 500);
  // }

  // showButton(btn) {
  //   this.contentList.nativeElement.parentElement.querySelector(`.${btn}`).style.display = 'inline-block';
  // }

  hideButton(btn) {
    setTimeout(() => {
        const buttonElement = this.contentList.nativeElement.parentElement.querySelector(`.${btn}`);
        if (buttonElement) {
            buttonElement.style.display = 'none';
        }
    }, 500);
}

showButton(btn) {
    const buttonElement = this.contentList.nativeElement.parentElement.querySelector(`.${btn}`);
    if (buttonElement) {
        buttonElement.style.display = 'inline-block';
    }
}

  // Mouse hover effects

  addHoverClass(e) {
    e.currentTarget.parentElement.parentElement.classList.add('hover');
    const parts = e.currentTarget.classList[1].split('-');
    const thumbnailPosition = Number(parts[parts.length - 1]);

    // Last section
    if (this.sectionCount === this.totalSectionCount - 1) {
      if (thumbnailPosition === this.totalThumbnailImages && this.contentsPerPage <= this.totalThumbnailImages) {
        e.currentTarget.parentElement.parentElement.classList.add('hover-left');
      } else if (thumbnailPosition === (this.totalThumbnailImages - this.contentsPerPage + 1)) {
        e.currentTarget.parentElement.parentElement.classList.add('hover-right');
      } else if (thumbnailPosition === 1) {
        e.currentTarget.parentElement.parentElement.classList.add('hover-right');
      } else {
        e.currentTarget.parentElement.parentElement.classList.add('hover-center');
      }
    } else {
      if ((thumbnailPosition % this.contentsPerPage === 0) || (thumbnailPosition === this.totalThumbnailImages)) {
        e.currentTarget.parentElement.parentElement.classList.add('hover-left');
      } else if (((thumbnailPosition - 1) % this.contentsPerPage === 0) ||
        (thumbnailPosition === (this.totalThumbnailImages - this.contentsPerPage + 1))) {
        e.currentTarget.parentElement.parentElement.classList.add('hover-right');
      } else {
        e.currentTarget.parentElement.parentElement.classList.add('hover-center');
      }
    }
  }


  removeHoverClass(e) {
    e.currentTarget.parentElement.parentElement.classList.remove('hover');
    e.currentTarget.parentElement.parentElement.classList.remove('hover-left');
    e.currentTarget.parentElement.parentElement.classList.remove('hover-right');
    e.currentTarget.parentElement.parentElement.classList.remove('hover-center');
  }

  getDefaultUrl(event) {

  }


  addToFavouritesResponseBody(content: ContentVO) {
    const obj = {
      contentInformation: [content]
    };
    return obj;
  }

  removeFromFavouritesRequestBody(content: ContentVO) {
    const obj = {
      subCategoryId: content.subCategoryId
    };
    return obj;
  }

}
