import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/utility/global-services/api.service';
import { Observable } from 'rxjs';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class ImageUploadService {

  constructor(private apiService: ApiService, private config : ConfigService) { }

  public uploadImage(image: File, assetId?: string, contentType?: string, imageType?: string): Observable<any> {
    /*  const formData = new FormData();
     formData.append('file', image); */
    const fileName = image.name;
    const url = this.config.json.apiIpPortValue + 'image/' + fileName + '/' + assetId + '/' + imageType + '/' + contentType;
    // const url = 'http://192.168.10.37:8090/' + 'files';
    return this.apiService.post(url, image);
  }

  public captureThumbnail(image: HTMLImageElement, assetId?: string): Observable<any> {
    const temp = {
      imageValue: image,
      id: assetId
    };
    const url = 'http://localhost:7600/uploadThumbnail';
    return this.apiService.post(url, temp);
  }


}
