import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ImageUploadService } from 'src/app/utility/global-services/image-upload.service';
import { ImageCropperDialogComponent } from './image-cropper-dialog/image-cropper-dialog.component';

class ImageSnippet {
  pending = false;
  status = 'init';
  constructor(public src: string, public file: File) { }
}

@Component({
  selector: 'app-onnet-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.sass']
})
export class ImageUploadComponent implements OnInit, OnChanges {

  constructor(
    private imageUploadService: ImageUploadService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar
  ) { }

  selectedFile: ImageSnippet;

  // padding value is (height/width * 100) or (100/aspect-ratio)
  imageTypes = [
    { name: 'landscape', padding: 56.25, aspectRatio: 16 / 9 },
    { name: 'portrait', padding: 150, aspectRatio: 2 / 3 },
    { name: 'banner', padding: 30, aspectRatio: 10 / 3 },
    { name: 'profile', padding: 100, aspectRatio: 1 / 1 }
  ];

  @Input() imageType: string;

  @Input() imageSourceUrl;

  // Id of the model type, to which it is being saved
  @Input() id = '';

  @Input() contentType = '';


  // Denotes whether this component is being used as part of a carousel
  @Input() bannerCarousel = false;

  @Input() cropped = true;

  // Emits the path, to where the image has been uploaded
  @Output() imagePath = new EventEmitter();

  // Emits boolean TRUE when a banner from the carousel is deleted
  @Output() deletedBanner = new EventEmitter();

  isCropped = false;

  paddingTop;

  imageUrl: string;
  imageName: string;
  aspectRatio;

  ngOnInit() {
    if (this.imageSourceUrl) {
      this.imageUrl = this.imageSourceUrl;
    }
    this.imageTypes.forEach(item => {
      if (item.name === this.imageType) {
        this.paddingTop = item.padding;
        this.aspectRatio = item.aspectRatio;
      }
    });
  }

  ngOnChanges() {
    if (this.imageSourceUrl) {
      this.imageUrl = this.imageSourceUrl;
    }
  }

  onSuccess() {
    this.selectedFile.pending = false;
    this.selectedFile.status = 'success';
    this.imagePath.emit(this.imageUrl);
  }

  onError() {
    this.selectedFile.pending = false;
    this.selectedFile.status = 'failed';
    this.selectedFile.src = '';
    this.snackBar.open('Upload failed', 'close', { duration: 2000, panelClass: 'error' });
  }

  processFile(imageInput) {

    const file = imageInput.files[0];
    this.imageName = file.name;

    if (this.cropped) {
      this.openDialog(file);
    } else {
      this.noCrop(file);
    }
  }

  noCrop(file) {
    const reader = new FileReader();
    reader.addEventListener('load', (event: any) => {

      this.selectedFile = new ImageSnippet(event.target.result, file);
      this.selectedFile.pending = true;

      this.upload(file);
    });

    if (file) {
      reader.readAsDataURL(file);
    }
  }

  upload(file) {
    this.imageUploadService.uploadImage(file, this.id, this.contentType, this.imageType).subscribe(
      (res) => {
        this.imageUrl = res.imageUrl;
        //console.log(this.imageUrl);
        this.onSuccess();
      },
      (err) => {
        this.onError();
      }
    );
  }

  removeImage() {
    this.imageUrl = undefined;
    this.imagePath.emit(this.imageUrl);
  }

  deleteBanner() {
    this.deletedBanner.emit(true);
  }

  openDialog(event): void {
    const dialogRef = this.dialog.open(ImageCropperDialogComponent, {
      // data: ''
      data: {
        image: event,
        aspectRatio: this.aspectRatio
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.selectedFile = result;
        this.upload(this.convertBlobToFile(result));
        this.isCropped = true;
      } else {
        this.noCrop(event);
      }
    });
  }

  convertBlobToFile(data) {
    const file = new File([data], this.imageName);
    return file;
  }

}
