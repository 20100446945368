import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

/**
 * Interceptor for handling error responses from all API requests
 *
 * Appropriate actions needs to be taken based on the error response
 * TODO: Need to check if providedIn: Root option can be added here, after apis' integration
 */
@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

    constructor(private snackbar: MatSnackBar) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(req).pipe(
            // retry(1),
            catchError((error: HttpErrorResponse) => {
                let errorMessage = '';
                if (error.error instanceof ErrorEvent) {
                    errorMessage = `Error: ${error.error.message}`;
                } else {
                    errorMessage = `Error Code: ${error.error.status}\nMessage: ${error.error.message}`;
                }
                //console.log(errorMessage);
                // this.snackbar.open(errorMessage, 'close', { duration: 5000 });
                return throwError(errorMessage);
            })
        );
    }
}
