import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthenticateService } from '../global-services/authenticate.service';

/**
 * Navigation guard that grants or removes access to certain parts of the navigation and
 * it implements the CanActivate interface
 */
@Injectable({
  providedIn: 'root'
})
export class CanActivateRouteGuard implements CanActivate {

  constructor(private auth: AuthenticateService) { }

  /**
   * Can also optionally pass in (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) as parameters
   * in cases where you would need information about the current route
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.auth.pathValidity(route, state);
  }
}
